import React, { useEffect } from 'react'
import { GoogleLogin } from 'react-google-login'
import config from '../../../config'
import { useTranslation } from 'react-i18next'
import { Text } from '../../../common/text'
import { gapi } from 'gapi-script'
import { sendRequestAuth } from '../../../api/api'
import { PATH } from '../../../const/path'
import { useNavigate } from 'react-router-dom'
import useAlert from '../../../hook/useAlert'

export const GoogleLoginComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {showAlert} = useAlert()

  useEffect(() => {
    function start () {
      gapi.client.init({
        clientId: config.REACT_APP_GOOOGLE_APP_ID,
        scope: 'email',
      })
    }

    gapi.load('client:auth2', start)
  }, [])

  const loginToSystem = async data => {
    const response = await sendRequestAuth('post',
      `${config.API}/auth/social-login`,
      data
    )
    if(!!response?.data?.success){
      if(+response?.data?.is_new_account === 1){
        // qua màn hình đăng ký tài khoản mới
        navigate(PATH.CONFIRM_INFO_SOCIAL, {
          state: response?.data?.data,
        })
      }else {
        // login vào trang chủ
        const shopInfo = response.data.data
        const deviceToken = JSON.parse(
           window.localStorage.getItem('device_token'),
        )
        if (!!deviceToken && deviceToken.length > 0) {
          const newDevices = deviceToken.filter(
             device => +device?.user_id !== +shopInfo?.user_id,
          )
          window.localStorage.setItem(
             'device_token',
             JSON.stringify([shopInfo, ...newDevices]),
          )
        } else {
          window.localStorage.setItem(
             'device_token',
             JSON.stringify([shopInfo]),
          )
        }
        window.location.href = '/admin'
      }
    }else{
      showAlert({
        type: 'danger',
        content: 'Login failed'
      })
    }
  }

  const responseGoogle = (response) => {
    if (!response?.error) {
      loginToSystem({
        'client_id': 'evoshop',
        'social_channel': 'google',
        'social_token': response?.tokenId
      })
    } else {
      showAlert({
        type: 'danger',
        content: 'Login failed'
      })
    }
    // You can handle the response data here, e.g., send it to your server for authentication.

  }

  return (
    <div>
      <GoogleLogin
        clientId={config.REACT_APP_GOOOGLE_APP_ID}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        render={renderProps => (
          <button onClick={renderProps.onClick} className={'btn-login-google'}>
            <img src={'/img/google.png'}/>
            <Text>{t('Google')}</Text>
          </button>
        )}

      />
    </div>
  )
}
