import {Popover} from '@material-ui/core'
import useCustomerAccount from 'Component/Account/hook/useCustomerAccount'
import {Text} from 'common/text'
import useGlobalContext from 'containerContext/storeContext'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {Tooltip} from "../../../../../common/tooltip";

export default function CustomerItem({account}) {
   const [anchorEl, setAnchorEl] = useState(null)
   const {t} = useTranslation()
   const [state] = useGlobalContext()
   const {user} = state
   const isCurrentAccount = account?.user_id === user?.user_id
   const {logoutAccount, switchAccount} = useCustomerAccount()

   const open = Boolean(anchorEl)
   const id = open ? 'customer-info-item-popper' : undefined

   const openItemOptionPopper = e => {
      e.stopPropagation()
      setAnchorEl(prev => (prev ? null : e.currentTarget))
   }

   const handleClose = () => setAnchorEl(null)

   return (
      <>
         <CustomerItemWrapper
            className="customer-management__account"
            data-open={open}
            data-clickable={!isCurrentAccount}
            onClick={() => !isCurrentAccount && switchAccount(account)}
         >
            <div style={{display: 'flex', alignItems: 'center', width: 'calc(100% - 36px)'}}>
               <div className="customer-management__title" style={{width: '100%'}}>
                  <div style={{display: 'flex', width: '100%'}}>
                     <img
                        src={account?.shop?.store_logo || '/img/logo-store.png'}
                        alt="avatar"
                        width={36}
                        height={36}
                        style={{
                           overflow: 'hidden',
                           borderRadius: '50%',
                           width: 36,
                           height: 36,
                        }}
                     />
                     <Text style={{
                        display: 'grid', marginLeft: '12px',
                        width: 'calc(100% - 48px)',
                     }}>
                        <Tooltip className={'account__tooltipV2'} title={account?.shop?.store_name} baseOn={'height'}>
                           {account?.shop?.store_name}
                        </Tooltip>
                        {isCurrentAccount ? (
                           <Text color={'#00AB56'} fontSize={12}>
                              {t('formLogin_selected_account')}
                           </Text>
                        ) : (
                           <Text color={'#606060'} fontSize={12}>
                              {account?.shop?.vip_code_jnt}
                           </Text>
                        )}
                     </Text>
                  </div>
               </div>
            </div>
            <OptionIcon
               width="20"
               height="20"
               viewBox="0 0 20 20"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
               onClick={openItemOptionPopper}
               data-open={open}
            >
               <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.83333 10.1618C5.83333 9.24134 5.08714 8.49518 4.16667 8.49518C3.24619 8.49518 2.5 9.24134 2.5 10.1618C2.5 11.0823 3.24619 11.8285 4.16667 11.8285C5.08714 11.8285 5.83333 11.0823 5.83333 10.1618ZM11.6667 10.1618C11.6667 9.24134 10.9205 8.49518 10 8.49518C9.0795 8.49518 8.33333 9.24134 8.33333 10.1618C8.33333 11.0823 9.0795 11.8285 10 11.8285C10.9205 11.8285 11.6667 11.0823 11.6667 10.1618ZM15.8333 8.49518C16.7538 8.49518 17.5 9.24134 17.5 10.1618C17.5 11.0823 16.7538 11.8285 15.8333 11.8285C14.9128 11.8285 14.1667 11.0823 14.1667 10.1618C14.1667 9.24134 14.9128 8.49518 15.8333 8.49518Z"
                  fill="#56697C"
               />
            </OptionIcon>
         </CustomerItemWrapper>

         <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'right',
            }}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'right',
            }}
         >
            <OptionContainer>
               <OptionItem onClick={(e) => {
                  setAnchorEl(null)
                  logoutAccount(account)
               }}>
                  {t('logout')}
               </OptionItem>
            </OptionContainer>
         </Popover>
      </>
   )
}

const CustomerItemWrapper = styled.div`
  &:hover {
    background: #edeff2;
    cursor: pointer;
  }

  &[data-open='true'] {
    &[data-clickable='true'] {
      background: #edeff2;
    }
  }

  &[data-clickable='false'] {
    &:hover {
      cursor: default;
      background: #f5f6f8;
    }
  }
  .account__tooltipV2{
     display: -webkit-box;
     height: 100%;
     -webkit-line-clamp: 1;
     -webkit-box-orient: vertical;
     overflow: hidden;
     text-overflow: ellipsis;
  }
`

const OptionIcon = styled.svg`
  cursor: pointer;

  &[data-open='true'] {
    path {
      fill: #e5101d;
    }
  }

  &:hover {
    path {
      fill: #e5101d;
    }
  }
`

const OptionContainer = styled.div`
  background: white;
  padding: 4px;
`

const OptionItem = styled.div`
  width: 130px;
  padding: 8px 12px;
  color: #ff424e;
  cursor: pointer;

  &:hover {
    background: #edeff2;
  }
`
