import { sendRequestAuth } from "api/api"
import config from "config"
import { useContext } from "react"
import { NotificationContext } from "../notification"
import useGlobalContext from "../../../containerContext/storeContext";
import {noticeCompAction} from "../provider/_action";

const useNotificationActions = () => {
  const {pageState,pageDispatch} = useContext(NotificationContext)
  const {activeTab, notice} = pageState
  const [, GlobalDispatch] = useGlobalContext()
  const handleFetchList = async (tabId, allowLoading = true, perPage = 20) => {
    const res = await sendRequestAuth('get', `${config.API}/notices/list?type=${tabId}&per_page=${perPage}&start=0`)
    if (res.data.success) {
      const filterData = res.data?.data?.filter(filter => +filter?.is_popup === 0 || +filter?.is_read === 1  )
      pageDispatch({type:noticeCompAction.GET_LIST_NOTICE,payload:{
          all:{
            list:filterData,
            perPage: res?.data?.meta?.per_page,
            total: res?.data?.meta?.total,
          }
        }})
      pageDispatch({type:noticeCompAction.GET_TOTAL_NOTICE,payload:{
          all: filterData?.filter(filter => +filter?.is_read === 0).length,
        }})
      GlobalDispatch({type:'NOTICE_TOTAL_UNREAD',payload:{
          all:filterData?.filter(filter => +filter?.is_read === 0).length,
          noticeManagement:true,
        }})
      pageDispatch({type:noticeCompAction.SET_CAN_LOAD_MORE_NOTICE,payload: +perPage >= res?.data?.meta?.total ? false : true})
    }
  }
  const handleReadAllList = async (page=20)=>{
    const res = await sendRequestAuth('get', `${config.API}/notices/list?per_page=${page}&start=0&type=0`)
    if (res.data.success ){
      pageDispatch({type:noticeCompAction.GET_LIST_NOTICE,payload:{
          all:{
            list:res.data.data,
            perPage:res.data.meta.per_page,
            total:res.data.meta.total,
          },
        }})
      pageDispatch({type:noticeCompAction.GET_TOTAL_NOTICE,payload:{
          all: res.data.meta.total_unread,
        }})
      GlobalDispatch({type:'NOTICE_TOTAL_UNREAD',payload:{
          all:res.data.meta.total_unread,
          noticeManagement:true
        }})
    }
  }
  const handleAllRead = async () => {
    const all = notice?.all?.list
    const findIsRead = all?.filter(filter => +filter.is_read === 0)
    if(findIsRead?.length > 0){
      GlobalDispatch({type:'NOTICE_TOTAL_UNREAD',payload:{
          noticeManagement:false
        }})
      const response =  await sendRequestAuth('get', `${config.API}/notices/read-all`)
      if(response?.data?.success) handleReadAllList()
    }
  }

  const onLoadMore = async (id,perPage=20,currentTotal) => {
    pageDispatch({type:noticeCompAction.SET_CAN_LOAD_MORE_NOTICE,payload:false})
    if ( perPage >= currentTotal) return
    await handleFetchList(activeTab, false, perPage + 20)
  }

  const handleRead = async (id,data,page)=>{
    GlobalDispatch({type:'NOTICE_TOTAL_UNREAD',payload:{
        noticeManagement:false
      }})
    const response = await sendRequestAuth('get', `${config.API}/notices/detail/${id}`)
    if(response?.data?.success){
      pageDispatch({type:noticeCompAction.GET_DETAIL_NOTICE,payload:response?.data?.data})
      const isPopup = response?.data?.data?.show_type
      if(+isPopup === 0 && response?.data?.data?.link === null) pageDispatch({type: noticeCompAction.OPEN_MODAL_NOTIFICATION,payload:true})
      else if(response?.data?.data?.link !== null) window.open( response?.data?.data?.link,'_blank')
      if(+data?.is_read === 0) handleReadAllList(page)

    }
  }

  return {
    handleAllRead,
    onLoadMore,
    handleRead,
  }
}

export default useNotificationActions