import React, { lazy } from "react"
import {PATH} from '../const/path'

import {
  addOnToolRoute,
  cashBookRoute, configAndSettingRoute,
  deliveryRoute,
  facebookRoute, orderRoute,
  partnerRoute, productRoute,
  warehouseRoute,
  accountantRoute,
  reportRoute,
  posRoute, notificationRoute
} from './groupRouter'

const ConfirmInfo = lazy(() => import('../Pages/confirmInfo'));
const NotLicensed = lazy(() => import("../Pages/permision/notLicensed"));
const NotPermission = lazy(() => import("../Pages/permision/notPermission"));
const AccountVerify = lazy(() => import('Pages/AccountVerify'));
const ConfirmInfoSocial = lazy(() => import('../Pages/confirmInfoSocial'));
const EvoPolicy = lazy(() => import("../Pages/policy"));
const renderNewPassword = lazy(() => import('../Pages/LoginForm/updatePassword'));
const ControlPanel = lazy(() => import('../Pages/ControlPanel/controlPanel'));
const LoginForm = lazy(() => import('../Pages/LoginForm/login'));
const ForgotPasswordForm = lazy(() => import('../Pages/ForgotPassword'));
const NotFound = lazy(() => import('Pages/404/index.'));
const NoConnection = lazy(() => import('Pages/noConnection'));

const publicRoutes = [
  {path: PATH.HOME, component: LoginForm},
  {path: PATH.LOGIN, component: LoginForm},
  {path: PATH.CONFIRM_INFO, component: ConfirmInfo},
  {path: PATH.CONFIRM_INFO_SOCIAL, component: ConfirmInfoSocial},
  {path: PATH.FORGOT_PASSWORD, component: ForgotPasswordForm},
  {path: PATH.NOT_FOUND, component: NotFound},
  {path: PATH.NO_CONNECTION, component: NoConnection},
  {path: PATH.UPDATE_PASSWORD, component: renderNewPassword},
  {path: PATH.NOT_FOUND, component: NotFound},
  {path: PATH.NOT_LICENSED, component: NotLicensed},
  {path: PATH.NOT_PERMISSION, component: NotPermission},
  {path: PATH.ACCOUNT_VERIFY, component: AccountVerify},
  { path: PATH.PRIVATE_POLICY, component: EvoPolicy},
]
const privateRoutes = [
  {path: PATH.ADMIN_DASHBOAR, component: ControlPanel, prefix: 'publish'},
  ...facebookRoute,
  ...warehouseRoute,
  ...partnerRoute,
  ...addOnToolRoute,
  ...deliveryRoute,
  ...cashBookRoute,
  ...productRoute,
  ...orderRoute,
  ...configAndSettingRoute,
  ...accountantRoute,
  ...reportRoute,
  ...posRoute,
  ...notificationRoute
]

export {publicRoutes, privateRoutes}