import {Box, Modal} from '@mui/material'
import {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import ReactImageFallback from 'react-image-fallback'
import {Button} from '../../../../common/button'
import {Text} from '../../../../common/text'
import {THEME_COLORS} from '../../../../common/theme/_colors'
import useGlobalContext from '../../../../containerContext/storeContext'
import {CustomToolTip} from '../../../tooltip/CustomTooltip'
import {ICON} from '../../account'
import {useUpdateLogo} from '../../hook/useUpdateLogo'
import {StoreAccountContext} from '../../reducer/context'
import {useAccountAction} from '../../reducer/reducer'
import './index.scss'
import {ICON_ACCOUNT} from 'Component/Account/interface/icon'
import CustomerItem from './component/customerItem'
import useCustomerAccount from 'Component/Account/hook/useCustomerAccount'

const Index = ({...props}) => {
  const {
    open,
    handleCloseModal,
    bolUpdate,
    setCloseAnchor,
  } = props
  const {t} = useTranslation()
  const {addNewAccount, logoutAllAccount, deviceTokenList} = useCustomerAccount()
  const [confirm, setConfirm] = useState(false)
  const [title, setTitle] = useState(false)

  const [aniModalClose, setAniModalClose] = useState(false)

  const handleCancelConfirm = () => {
    setConfirm(false)
    setTitle(false)
  }

  const handleLogoutConfirmed = () => {
    setConfirm(false)
    setTitle(false)
    logoutAllAccount()
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          if (!bolUpdate) {
            setAniModalClose(true)
            setCloseAnchor()
            setTimeout(() => {
              handleCloseModal()
            }, 300)
          } else {
            setConfirm(true)
          }
        }}
        id={'modal_user_profile'}
      >
        <Box className={`box_modal-account ${aniModalClose && 'modal_custom'}`}>
          <div
            className="icon-close"
            onClick={() => {
              if (!bolUpdate) {
                setAniModalClose(true)
                setCloseAnchor()
                setTimeout(() => {
                  handleCloseModal()
                }, 300)
              } else {
                setConfirm(true)
              }
            }}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 4C0 1.79086 1.79086 0 4 0H28V28H4C1.79086 28 0 26.2091 0 24V4Z"
                fill="#E5101D"
              />
              <path
                d="M19.6 7L14 12.6L8.4 7L7 8.4L12.6 14L7 19.6L8.4 21L14 15.4L19.6 21L21 19.6L15.4 14L21 8.4L19.6 7Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="customer-management">
            <div className="customer-management__box">
              <Text
                fontSize={20}
                fontWeight={700}
                style={{display: 'block', marginBottom: '8px'}}
              >
                {t('formLogin_AccountManagement')}
              </Text>
            </div>
            <div className="customer-management__account-wrapper scroll-custom">
              {deviceTokenList.map(item => <CustomerItem key={item?.user_id} account={item} />)}
            </div>
            <div className="customer-management__footer">
              <div className="customer-management__add">
                {ICON_ACCOUNT.plus}
                <Text fontSize={15} fontWeight={500} onClick={addNewAccount}>
                  {t('formLogin_Add_Account')}
                </Text>
              </div>
              <div className="customer-management__logout">
                <Text fontSize={15} color={'#FF424E'} onClick={() => {setConfirm(true)
                  setTitle(t('formLogin_logout_allacount'))
                }} >
                  {t('formLogin_LogoutAll')}
                </Text>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={confirm}
        onClose={handleCancelConfirm}
        className="modal_confirm"
      >
        <Box className="box_confirm_account">
          <Text as={'p'} fontSize={20} fontWeight={600} className={'txt_title'}>
            {t('logout_confirmation')}
          </Text>
          <Text as={'p'} className={'txt_content'}>
            {!!title ? title : t('formLogin_selected_account')}
          </Text>
          <div className="grp_btn">
            <Button
              onClick={handleCancelConfirm}
              className="dismiss"
              appearance={'ghost'}
            >
              {t('general_cancel')}
            </Button>
            <Button className="save" onClick={handleLogoutConfirmed}>
              {t('logout')}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  )
}
export default Index
