import {Alert} from 'common/alert'
import useGlobalContext from 'containerContext/storeContext'
import {StyledWrapper} from './_styled'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import config from '../../config'

export const Wrapper = ({...props}) => {
  const [globalState] = useGlobalContext()
  const {alert, shouldMinimizeSidebar} = globalState

  const location = useLocation()
  useEffect(() => {
    TagManager.initialize({
      gtmId: config?.TRACKING_ID,
    })
    sendPageViewEvent()
  }, [location])
  const sendPageViewEvent = () => {
    const currentURL = location.pathname
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        page_path: currentURL,
      },
    })
  }
  return (
    <StyledWrapper
      {...props}
      id={globalState.isLogin && 'content-wrap'}
      data-expand={shouldMinimizeSidebar}
    >
      {props?.children}

      {alert?.open && (
        <Alert
          duration={alert?.duration}
          open={true}
          type={alert?.type}
          onClose={alert?.onClose}
        >
          {alert?.content}
        </Alert>
      )}
    </StyledWrapper>
  )
}
