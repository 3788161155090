import {postData, sendRequestAuth} from 'api/api'
import {getUrlLogin} from 'api/url'
import {PATH} from 'const/path'
import useGlobalContext from 'containerContext/storeContext'
import React, {useEffect, useLayoutEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {ICONS} from 'Pages/confirmInfo/interface/_icons'
import config from 'config'
import {Radio} from 'common/form/radio'
import {Text} from 'common/text'
import {Tooltip} from 'common/tooltip'
import {LanguageSelect} from 'Component/Language'
import {Button} from 'common/button'
import ControlPanel from 'Pages/ControlPanel/controlPanel'
import {GoogleLoginComponent} from './component/googleLogin'
import {FacebookLoginComponent} from './component/facebookLogin'
import styled from 'styled-components'
import {ICON_ACCOUNT} from 'Component/Account/interface/icon'
import useAlert from "../../hook/useAlert";

function loginFromKeyboard() {
   try {
      const buttonConfirm = document.getElementsByClassName('button-confirm')
      buttonConfirm[0].click()
   } catch (error) {
      console.error(
         `================ error at loginFromKeyboard==================${error}`,
      )
   }
}

const listener = event => {
   if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      loginFromKeyboard()
   }
}

export default function FormLogin({onFailure, onSuccess}) {
   const location = useLocation()
   const navigate = useNavigate()
   const [state, dispatch] = useGlobalContext()
   const [isVI, setIsVI] = useState(false)
   const [isEmpty, setIsEmpty] = useState(false)
   const [isUsernameEmpty, setIsUsernameEmpty] = useState(false)
   const [errorPass, setErrorPass] = useState()
   const [checkDevice, setCheckDevice] = useState(false)
   const {showAlert} = useAlert()

   const checkPasswordVN = value => {
      const regex =
         /[ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]/
      const checkVN = value.match(regex)
      setIsVI(checkVN)
   }

   const isEmail = value => {
      let regex =
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regex.test(value)
   }

   //***************************
   const [username, setUserName] = useState(location.state?.username || '')
   const [password, setPassword] = useState(location.state?.password || '')
   const [firstTime, setFirstTime] = useState(false)
   const [isSavePass, setIsSavePass] = useState(false)
   const [isShowPass, setShowHidePass] = useState(false)
   const [message, setMessage] = useState('')
   const [error, setError] = useState(false)
   const [loading, changeLoading] = useState(false)
   const [option, setOption] = useState(location.state?.option || 0)
   const nav = useNavigate()
   // action here
   const handleSubmit = () => {
      if (errorPass) return

      const usrname = username?.trim()
      const pass = password?.trim()

      if (!usrname && !pass) {
         setError(true)
         setIsUsernameEmpty(false)
         setIsEmpty(false)
      } else {
         if (!usrname) setIsUsernameEmpty(true)
         if (!pass) setIsEmpty(true)
         if (!usrname && !pass) {
            setError(false)
         }
      }

      setFirstTime(true)
      if (!usrname || !pass) return
      const url = getUrlLogin({option})
      const data = {
         client_id: 'evoshop',
         username: usrname,
         password: pass,
         client_version: '',
      }

      changeLoading(true)
      postData(url, data)
         .then(res => {
            changeLoading(false)
            if (res.data && res.data.success) {
               if (onSuccess) onSuccess({...res.data, option})
               // clear data
               setUserName('')
               setPassword('')
               setError(false)
               setMessage('')
               setFirstTime(false)
               const shopInfo = res.data.data
               const deviceToken = JSON.parse(
                  window.localStorage.getItem('device_token'),
               )
               if (!!deviceToken && deviceToken.length > 0) {
                  const newDevices = deviceToken.filter(
                     device => +device?.user_id !== +shopInfo?.user_id,
                  )
                  window.localStorage.setItem(
                     'device_token',
                     JSON.stringify([shopInfo, ...newDevices]),
                  )
               } else {
                  window.localStorage.setItem(
                     'device_token',
                     JSON.stringify([shopInfo]),
                  )
               }
               const {access_token} = shopInfo
               window.localStorage.setItem('token', access_token)
               dispatch({type: 'SET_USER', payload: res.data})
               window.localStorage.setItem('isShowPopupEvo', JSON.stringify({id:'', status: false}))
               window.location.reload()
            }
            if (option === 0 && res.data && res.data.errors) {
               if (res.data.otp_required)
                  navigate(PATH.ACCOUNT_VERIFY, {
                     state: {
                        message: res.data.errors.message,
                        user_id: res.data.user_id,
                     },
                  })
               else setMessage(res.data.errors.message)
            }
            // else if (option === 1 && !res.data.success) {
            //   setMessage(res.data.message)
            // }

            if (res.data && !!!res.data?.success) {
               if (onFailure) onFailure({...res.data, option})
               if (res?.data?.change_password_required) {
                  dispatch({type: 'SET_USER_CHANGE_PASSWORD', payload: res?.data})
                  nav(PATH.UPDATE_PASSWORD)
               }
            }
         })
         .catch(() => {
            changeLoading(false)
            dispatch({type: 'SET_USER', payload: {}})
            dispatch({type: 'SET_LOGOUT'})
         })
   }

   const onChangeUserName = data => {
      setUserName(
         data && data.target && data.target.value ? data.target.value : null,
      )
   }
   const onChangePassword = data => {
      setPassword(
         data && data.target && data.target.value ? data.target.value : null,
      )
   }

   const showHidePassword = () => {
      setShowHidePass(!isShowPass)
   }

   //************************

   const {t} = useTranslation()
   const [size, setSize] = useState([0, 0])
   useLayoutEffect(() => {
      function updateSize() {
         setSize([window.innerWidth, window.innerHeight])
      }

      window.addEventListener('resize', updateSize)
      updateSize()
      return () => window.removeEventListener('resize', updateSize)
   }, [])
   const [width, height] = size
   const deviceToken = JSON.parse(window.localStorage.getItem('device_token'))

   useEffect(() => {
      if (!!deviceToken && deviceToken.length > 0 && !!!location?.state?.addNew) {
         setCheckDevice(true)
      } else {
         setCheckDevice(false)
      }
   }, [])

   if (state && state.isLogin) {
      return <ControlPanel/>
   }

   const switchAccount = async (account) => {
      const response = await sendRequestAuth('post', `${config.API}/auth/switch-account`,
         {
            "client_id": "evoshop",
            "client_version": "",
            "user_id": account?.user_id,
            "client_device_token": account?.client_device_token
         }
      )
      if (response.data.success) {
         let deviceToken = JSON.parse(window.localStorage.getItem('device_token'))
         deviceToken = deviceToken.filter(item => item.user_id !== account.user_id)
         deviceToken = [account, ...deviceToken]
         window.localStorage.setItem('device_token', JSON.stringify(deviceToken || []))
         showAlert({
            type: 'success',
            content: t('formLogin_Accessing')
         })
         window.location.reload()
      } else {
         showAlert({
            type: 'danger',
            content: response.data?.message || t('formLogin_switch_account_error')
         })
      }
   }
   return (
      <StyledLoginForm
         className={width < 1200 ? 'login-form mini-login-form' : 'login-form'}
      >
         {!checkDevice ? (
               <div className={'group-login-form'}>
                  <div className="group-logo">
                     <img id={'logo-loginform'} src={'/img/logo.png'} alt={'logo'}/>
                  </div>
                  <div className={'title-login-form'}>
                     <p>Welcome to evoshop</p>
                  </div>

                  <div className="flex title-login-form__area">
                     <div className="flex align-items-center">
                        <Radio
                           id="evoshop-radio"
                           checked={option === 0}
                           onClick={() => setOption(0)}
                        />
                        <Text
                           as="label"
                           htmlFor="evoshop-radio"
                           style={{margin: '0 9px', cursor: 'pointer'}}
                        >
                           {t('account')} evoshop
                        </Text>
                     </div>
                     <div className="flex align-items-center">
                        <Radio
                           id="vippro-radio"
                           checked={option === 1}
                           onClick={() => setOption(1)}
                        />{' '}
                        <Text
                           as="label"
                           htmlFor="vippro-radio"
                           style={{marginLeft: 9, cursor: 'pointer'}}
                        >
                           {t('customer')} VIP Pro
                        </Text>
                     </div>
                  </div>

                  {message ? (
                     <div className={'error-all-form'}>
                        <img
                           id={'logo-error-all-form'}
                           src={'/svg/error.svg'}
                           alt={'icon-error'}
                        />
                        <p>{message}</p>
                     </div>
                  ) : null}

                  {error ? (
                     <div className={'error-all-form'}>
                        <img
                           id={'logo-error-all-form'}
                           src={'/svg/error.svg'}
                           alt={'icon-error'}
                        />
                        <p>{t('warning_form')}</p>
                     </div>
                  ) : null}

                  {option === 0 ? (
                     <>
                        <div className="user-name-field">
                           <label htmlFor="username">{t('account')}</label>
                           <input
                              placeholder={t('placeholder_account')}
                              tabIndex="1"
                              className={
                                 (!username && firstTime) ||
                                 message ||
                                 error ||
                                 isUsernameEmpty
                                    ? 'border-red-input upos-text'
                                    : 'upos-text'
                              }
                              value={username}
                              onChange={e => {
                                 //reset summary error
                                 setMessage('')
                                 setError(false)
                                 setFirstTime(false)

                                 const value = e.target.value
                                 onChangeUserName(e)
                                 if (value?.trim()) setIsUsernameEmpty(false)
                                 else setIsUsernameEmpty(true)
                              }}
                              type={'text'}
                              required={''}
                              id={'username'}
                              autoComplete={'on'}
                              onBlur={e => {
                                 const value = e.target.value
                                 //check empty
                                 if (!value?.trim()) setIsUsernameEmpty(true)
                              }}
                              onFocus={e => {
                                 setIsUsernameEmpty(false)
                              }}
                           />
                           {isUsernameEmpty && !error && (
                              <div className={'password-message'}>
                                 {t('error_account_notify')}
                              </div>
                           )}
                        </div>

                        <div className={'password-field'}>
                           <div className={'password-forgot'}>
                              <label htmlFor={'password'}>{t('password')}</label>
                           </div>
                           <div className="group-inpput-login-form">
                              <input
                                 placeholder={t('password')}
                                 tabIndex="2"
                                 className={
                                    (!password && firstTime) || message || error || isEmpty
                                       ? 'border-red-input upos-text'
                                       : 'upos-text'
                                 }
                                 type={!isShowPass ? 'password' : 'text'}
                                 required={''}
                                 id={'password'}
                                 autoComplete={'on'}
                                 value={password}
                                 onChange={e => {
                                    //reset summary error
                                    setMessage('')
                                    setError(false)
                                    setFirstTime(false)

                                    const value = e.target.value
                                    if (value?.trim()) setIsEmpty(false)
                                    else setIsEmpty(true)
                                    checkPasswordVN(e.target.value)
                                    onChangePassword(e)
                                 }}
                                 onBlur={e => {
                                    !e.target.value.trim() && setIsEmpty(true)
                                 }}
                                 onFocus={e => {
                                    !e.target.value && setIsEmpty(false)
                                 }}
                              />
                              <img
                                 className={
                                    !isShowPass ? 'icon-hide-pass' : 'icon-show-pass'
                                 }
                                 src={
                                    !isShowPass ? '/svg/close-eye.svg' : '/svg/open-eye.svg'
                                 }
                                 onClick={() => showHidePassword(isShowPass)}
                                 alt={'icon'}
                              />
                           </div>
                           <div
                              className={'forgot-password-button cursor-pointer upos-text'}
                           >
                              <div className={'error-message-password'}>
                                 {isEmpty && !error && (
                                    <div className={'password-message'}>
                                       {t('error_password_notify')}
                                    </div>
                                 )}
                              </div>
                              <div className={'link-forgot-password'}>
                                 <Link to={'/forgot-password'} tabIndex="3">
                                    {t('forgot_password')}
                                 </Link>
                                 <Tooltip
                                    title={t('warning_forgot_password')}
                                    placement="bottom"
                                 >
                                    <div
                                       style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginLeft: '5px',
                                       }}
                                    >
                                       {ICONS.question}
                                    </div>
                                 </Tooltip>
                              </div>
                           </div>
                        </div>
                        <Button
                           className={
                              username && password
                                 ? 'button-confirm cursor-pointer'
                                 : 'button-confirm cursor-pointer'
                           }
                           onClick={() => handleSubmit(setIsVI, setIsEmpty)}
                           type="submit"
                        >
                           {loading ? (
                              <img src={'/svg/loading.svg'} alt={'loading'}/>
                           ) : (
                              t('Login')
                           )}
                        </Button>
                     </>
                  ) : (
                     <div className={'login-notification'}>
                        <Text fontSize={16} style={{marginBottom: 12}}>
                           {t('login_notification')}
                        </Text>
                        <img
                           className={'login-notification__qr'}
                           src="/img/qr-zalo.png"
                           alt=""
                        />
                        <img
                           className={'login-notification__ring'}
                           src="/img/ring.png"
                           alt=""
                        />
                        <Text fontSize={14} style={{marginBottom: 4}}>
                           {t('login_qr_zalo')}
                        </Text>
                        <Text
                           as={'p'}
                           href={'https://zalo.me/0852017493'}
                           target={'_blank'}
                           color={'#0B101A'}
                           fontSize={17}
                           fontWeight={700}
                        >
                           Zalo:{' '}
                           <Text fontSize={17} fontWeight={500} color={'#1A94FF'}>
                              0852017493
                           </Text>
                        </Text>
                     </div>
                  )}
                  <div className={'login-other'}>
                     <div className={'login-other__line'}></div>
                     <Text as={'p'} color={'#9E9EA7'}>
                        {t('login_other')}
                     </Text>
                     <div className={'login-other__line'}></div>
                  </div>
                  <div className={'login-social'}>
                     <GoogleLoginComponent/>
                     <FacebookLoginComponent/>
                  </div>
                  {deviceToken?.length > 0 && <div className={'account-list-back'}>
                     <Text onClick={() => setCheckDevice(true)} color={'#0052FF'}
                           style={{cursor: 'pointer'}}>{t('formLogin_Return_to_Accounts_List')}</Text>
                  </div>}
                  <div className={'comfirm-info__contact-login-form'}>
                     <div className="comfirm-info__phone-call">
                        <a href="tel: 1900 1511">
                           <img
                              className={'cell-phone'}
                              src={'/svg/PhoneCall.svg'}
                              alt="phoneCall"
                           />
                           {config.PHONE}
                        </a>
                        &nbsp;({t('chanel_number')} 2)
                     </div>
                  </div>
               </div>
            )
            : (
               <div className={'group-login-form-account'}>
                  <div className={'title-login-form-account'}>
                     <Text fontSize={32} fontWeight={700} style={{display: 'block'}}>
                        {t('formLogin_Select_Account')}
                     </Text>
                     <Text fontSize={18}>{t('formLogin_Select_Account_to_Access')}</Text>
                  </div>
                  <div className={'common-scrollbar list-account-container'} style={{
                     maxHeight: '318px',
                     overflow: 'auto',
                     marginRight: deviceToken.length > 4 ? -14 : -8,
                     paddingRight: 8
                  }}>
                     {deviceToken.map((item, key) => (
                        <div className="list-account" key={key} onClick={() => switchAccount(item)}>
                           <div style={{display: 'flex', width: '100%'}}>
                              <img
                                 src={item?.shop?.store_logo || '/img/logo-store.png'}
                                 alt="avatar"
                                 width={36}
                                 height={36}
                                 style={{
                                    overflow: 'hidden',
                                    borderRadius: '50%',
                                    width: 36,
                                    height: 36,
                                 }}
                              />
                              <Text style={{display: 'grid', marginLeft: '12px',
                                 width: 'calc(100% - 48px)'}} fontWeight={600}>
                                 <Tooltip className={'account__tooltipV2'} title={item?.shop?.store_name} baseOn={'height'}>
                                    {item?.shop?.store_name}
                                 </Tooltip>
                                 <Text color={'#606060'} fontSize={12}>
                                    {item?.shop?.vip_code_jnt}
                                 </Text>
                              </Text>
                           </div>
                        </div>))}
                  </div>
                  <div className="account-add" style={{marginTop: 8}} onClick={() => setCheckDevice(false)}>
                     {ICON_ACCOUNT.user}
                     <Text
                        style={{
                           display: 'flex',
                           fontSize: 15,
                           fontWeight: 500,
                        }}
                     >
                        {t('formLogin_Use_Different_Account')}
                     </Text>
                  </div>
               </div>
            )
         }

         <div className={'language-content'} style={{position: 'relative'}}>
            <div className={'language-item'}>
               <LanguageSelect/>
            </div>
         </div>
      </StyledLoginForm>
   )
}
const StyledLoginForm = styled.div`
  
   .list-account {
     &:last-child{
      margin-bottom: 0;
     }
   }
  .language-item{
     position: absolute;
     top: 32px;
     right: 32px;
     width: 160px;
  }
  .login-notification {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 20px;
    width: 446px;
    margin-bottom: 32px;

    border-radius: 8px;
    background: rgba(255, 159, 65, 0.1);

    &__ring {
      position: absolute;
      right: -16px;
      top: -21px;
    }
  }
  .login-other {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    &__line {
      height: 1px;
      background: #e7e7e9;
      width: calc((100% - 136px) / 2);
    }
  }
  @media only screen and (max-height: 660px) {
    .login-notification {
      gap: 12px;
      padding: 16px;
      width: 374px;
      &__ring {
        width: 50px;
        position: absolute;
        right: -12px;
        top: -15px;
      }
      &__qr {
        width: 25%;
      }
    }
    .login-other {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      &__line {
        height: 1px;
        background: #e7e7e9;
        width: calc((100% - 136px) / 2);
      }
    }
  }
`
