import { Box, Modal } from '@mui/material'
import Popover from '@mui/material/Popover'
import { memo, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import cls from 'clsx'
import { useTranslation } from 'react-i18next'
import { Button } from '../../common/button'
import { Tooltip } from '../../common/tooltip'
import { DISPLAY_NAME_MENU } from '../../const/display_name_menu'
import useGlobalContext from '../../containerContext/storeContext'
import Account from '../Account'
import { CustomToolTip } from '../tooltip/CustomTooltip'
import Tab from './Tab'
import css from './index.module.scss'

export default memo(function Index () {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [, setShowUser] = useState(false)
  const [avatar,] = useState('')
  const [toggle, setToggle] = useState(false)
  const [bolUpdate, setBolUpdate] = useState(false)
  const [aniModalClose, setAniModalClose] = useState(false)
  const [state, dispatch] = useGlobalContext()
  const [tab, setTab] = useState('user')
  const data = state.user
  const { user, userInfoPopup } = state
  const nav = useNavigate()
  const [searchParams] = useSearchParams()
  const changePassword = searchParams.get('changePassword') ?? ''
  const { shopInfo } = state

  useEffect(() => {
    if (+changePassword === 1) {
      setShowUser(true)
      setOpen(true)
      setAniModalClose(false)
      setTab('password')
    }
  }, [])

  const [anchorEl, setAnchorEl] = useState(null)
  const handleShowProfile = (e) => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const openProfile = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const isUpdate = bol => bol ? setBolUpdate(true) : setBolUpdate(false)

  useEffect(() => {
    window.addEventListener('click', function (e) {
      const isPopup = document.getElementById('btn_user_profile')?.contains(e.target)
      const isPopupBtn = document.getElementById('btn_duration')?.contains(e.target)
      if (!isPopup && !isPopupBtn) {
        setToggle(false)
      }
    })
    return function cleanupListener () {
      window.removeEventListener('click', () => {})
    }
  }, [])

  return (
    <>
      <div className={cls(css.wrapper)}>
        <Tooltip title={t('store_information_and_account_information')}>
          <img aria-describedby={id}
               variant="contained"
               onClick={handleShowProfile}
               className={cls(css.icon_avatar)}
               src={shopInfo?.store_logo || '/img/iconMenu/defaultAvatar.png'}
               alt={'avatar'}
          />
        </Tooltip>
        <Popover
          sx={{ top: 14, left: -19 }}
          id={id}
          open={openProfile}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={`${cls(css.account)}`}>
            <Account 
              setAnchorEl={setAnchorEl} 
              setUserInfoTab={setTab} 
              setUserInfoOpen={setOpen}
              setUserInfoAnimationClose={setAniModalClose}
            />
          </div>
        </Popover>
        <Modal
          open={open}
          onClose={() => {
            if (!bolUpdate) {
              setAniModalClose(true)
              setTimeout(() => {
                setOpen(false)
                setToggle(false)
              }, 300)
            } else {
              setConfirm(true)
            }
          }}
          id={'modal_user_profile'}
        >
          <Box className={`${cls(css.box_modal)} ${aniModalClose && cls(css.modal_custom)}`}>
            <div className={cls(css.dismiss)}
                 onClick={() => {
                   if (!bolUpdate) {
                     setAniModalClose(true)
                     setTimeout(() => {
                       setOpen(false)
                     }, 300)
                   } else {
                     setConfirm(true)
                   }
                 }}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 1L1 13M1 1L13 13" stroke="#F4F7FC" strokeWidth="1.4" strokeLinecap="round"
                      strokeLinejoin="round"/>
              </svg>
            </div>
            <div className={cls(css.general)}>
              <img
                className={cls(css.bgp)}
                src={'/img/iconMenu/bgProfile.svg'}
                alt={'background'}
              />
              <div className={cls(css.grp_avatar)}>
                <img
                  className={cls(css.avatar)}
                  src={(state.user && state.user.avatar) || '/img/iconMenu/deAvatar.png'}
                  alt={'avatar'}
                />
              </div>
              <div className={cls(css.content)}>
                <div className={cls(css.info)}>
                  <CustomToolTip title={
                    <p className={cls(css.name)}>{data.fullname}</p>
                  } arrow>
                    <p className={cls(css.name)}>{data.fullname}</p>
                  </CustomToolTip>
                </div>
              </div>
              <Tab userProfile={data} tab={tab} isUpdate={isUpdate}/>
            </div>
          </Box>
        </Modal>

        <Modal
          open={confirm}
          onClose={() => {
            setConfirm(false)
          }}
          className={cls(css.modal_confirm)}
        >
          <Box className={cls(css.box_confirm)}>
            <div>
              <p>{t(DISPLAY_NAME_MENU.GENERAL.CONFIRM_LEAVING_CONTENT)}</p>
              <div className={cls(css.grp_btn)}>
                <button className={cls(css.dismiss)}
                        onClick={() => {
                          setConfirm(false)
                        }}
                >
                  {t(DISPLAY_NAME_MENU.GENERAL.CLOSE)}
                </button>
                <Button className={cls(css.save)}
                        onClick={() => {
                          setConfirm(false)
                          setAniModalClose(true)
                          setTimeout(() => {
                            setOpen(false)
                            setBolUpdate(false)
                          }, 300)
                        }}
                >{t(DISPLAY_NAME_MENU.GENERAL.CONFIRM)}
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  )
})
