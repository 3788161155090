import React from 'react';
import {Box, Modal} from "@mui/material";
import ReactImageFallback from "react-image-fallback";
import 'react-quill/dist/quill.snow.css'
import styled from "styled-components";
import {Text} from "../../common/text";
const Index = ({...props}) => {
    const {openModal, setOpenModal, item } = props
    const close = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.6">
            <rect width="32" height="32" rx="16" fill="#717483"/>
            <path d="M22 10L10 22M10 10L22 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>
    return (
        <Modal
            open={openModal}
        >
            <Box>
                <StyleModalNotification>
                    <div className={'confirm-modal-notice-comp-box'}>
                        <div data-position={item?.image_position} className={'confirm-modal-notice-comp'}>
                            <div className='confirm-modal-notice-comp_header-notification'>
                                <ReactImageFallback
                                    src={item?.image}
                                    className={'confirm-modal-notice-comp_header-image'}
                                    fallbackImage={+item?.notice_type === 0 ? '/img/banner-notice-system.png' : '/img/banner-notice.png'}
                                />
                                <div onClick={()=>setOpenModal(false)}
                                     className='confirm-modal-notice-comp_header-close_modal'>{close}</div>
                            </div>
                            <div className='confirm-modal-notice-comp_body-notification'>
                                <div className='confirm-modal-notice-comp_body-scroll preview-content' >
                                    <div style={{textAlign:'center',marginBottom:'10px',paddingTop:'3px'}}>
                                        <Text fontSize={24} lineHeight={30} fontWeight={600}>{item?.title}</Text>
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html:item?.content}}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </StyleModalNotification>
            </Box>
        </Modal>

    )
}
export default Index;
const StyleModalNotification = styled.div`
    .confirm-modal-notice-comp{
      .ql-editor{
        white-space: normal!important;
      }
      &-box{
        width: 688px;
        margin: 8rem auto;
        @media screen and (max-width: 1440px){
          margin: 3rem auto;
        }
        @media screen and (max-width: 1366px){
        margin: 0.6rem auto;
        }
        hr{
          margin: 24px 0;
        }
        span{
          line-height: 150%;
        }
        p{
          margin-bottom: 10px;
          font-weight: 350;
        }
      }
      height: auto;
      background: #FFFFFF;
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-radius: 8px;
    &[data-position='top']{
    flex-direction: column;
  }
  &[data-position='bottom']{
    flex-direction: column-reverse;
  }
  &[data-position='left']{
    flex-direction: row;
    .confirm-modal-notice-comp_header-notification{
      width: 344px;
      height: 600px;
      overflow: hidden;
    }
    .confirm-modal-notice-comp_body-notification{
      width: 344px;
      height: 600px;
      padding: 24px 10px;
    }
  }
  &[data-position='right']{
    flex-direction: row-reverse;
    .confirm-modal-notice-comp_header-notification{
      width: 344px;
      height: 600px;
      overflow: hidden;
    }
    .confirm-modal-notice-comp_body-notification{
      width: 344px;
      height: 600px;
      padding: 24px 10px;
    }
  }
  &_header-notification{
    width: 100%;
    height: 222px;

  }
  &_header-image{
      width: 100%;
    height: 100%;
  }
  &_header-close_modal{
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
  &_body-notification{
    padding: 24px 32px;
    height: 454px;
    overflow: hidden;
    @media screen and (max-width: 1366px){
      height: 380px;
    }

  }
  &_body-scroll{
    height: 100%;
    overflow: auto;
  }
 }
.preview-content{
    p {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
  }
    ul{
    list-style-type: disc;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
      li{
        display: list-item; 
      }
    }

    ol{
    list-style-type: decimal;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
      lo{
        display: list-item; 
      }
    }
}
`
