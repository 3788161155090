import React from "react";
import {StyledStoreConfigDetail} from "./styles";
import {Text} from "../../../../../../common/text";
import {Switch} from "../../../../../../common/switch";
import {THEME_COLORS} from "../../../../../../common/theme/_colors";
import {useUpdateStoreConfig} from "../../../../hook/useUpdateStoreConfig";
import {useTranslation} from "react-i18next";

const Index = ({...props})=>{
    const {t} = useTranslation()
    const {
        warningPhone,
        bulkOrder,
        quantityLowRate,
        functions
    }=useUpdateStoreConfig()
    const {activeValue} = bulkOrder

    return(
        <StyledStoreConfigDetail>
            <div className={'store-config'}>
                <div className={'store-config-btn-update'}>
                  <div className={'store-config-title'} >
                    <Text fontWeight={600}>{t('store_config_sell')}</Text>
                    <div className={'store-config-switch-alert'}>
                      <Switch checked={warningPhone} disabled={true}/>
                      <Text style={{marginLeft:'8px'}} fontSize={15}>{t('store_config_warning_phone_create')}</Text>
                    </div>
                  </div>
                  <Text style={{width:'67px',cursor:'pointer'}} fontSize={15} onClick={functions.changeEdit} color={THEME_COLORS.blue_500}>{t('update')}</Text>
                </div>
                <div style={{display:'flex'}}>
                    <div className={'store-config-order-inventory'}>
                        <Text fontWeight={600}>{t('ware_house')}</Text>
                        <div className={'store-config-inventory'}>
                            <Text  style={{marginRight:'16px',width:'144px'}} color={'#7A92A5'}>{t('store_config_warning_quantity')}</Text>
                            <Text color={'#151624'} fontSize={15}>{quantityLowRate?.value}</Text>
                        </div>
                    </div>
                </div>

                <div className={'store-config-order-inventory'}>
                    <Text fontWeight={600}>{t('auto_bill')}</Text>
                    <div className={'store-config-inventory'}>
                        <Text  style={{marginRight:'16px',width:'144px'}} color={'#7A92A5'}>{t('setting_print')}</Text>
                        <Text color={'#151624'} fontSize={15}>{t(activeValue?.name)}</Text>
                    </div>
                </div>
            </div>
        </StyledStoreConfigDetail>
    )
}
export default Index;