export const SIDEBAR_MENU_ICONS = {
  accountant: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 11V15M18 9V13M17 4C19.4487 4 20.7731 4.37476 21.4321 4.66544C21.5199 4.70415 21.5638 4.72351 21.6904 4.84437C21.7663 4.91682 21.9049 5.12939 21.9405 5.22809C22 5.39274 22 5.48274 22 5.66274V16.4111C22 17.3199 22 17.7743 21.8637 18.0079C21.7251 18.2454 21.5914 18.3559 21.3319 18.4472C21.0769 18.5369 20.562 18.438 19.5322 18.2401C18.8114 18.1017 17.9565 18 17 18C14 18 11 20 7 20C4.55129 20 3.22687 19.6252 2.56788 19.3346C2.48012 19.2958 2.43624 19.2765 2.3096 19.1556C2.23369 19.0832 2.09512 18.8706 2.05947 18.7719C2 18.6073 2 18.5173 2 18.3373L2 7.58885C2 6.68009 2 6.2257 2.13628 5.99214C2.2749 5.75456 2.40859 5.64412 2.66806 5.55281C2.92314 5.46305 3.43803 5.56198 4.46783 5.75985C5.18862 5.89834 6.04348 6 7 6C10 6 13 4 17 4ZM14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5C13.3807 9.5 14.5 10.6193 14.5 12Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  arrow: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99954 13.0938L15.0079 8.08542L13.8304 6.90625L9.99954 10.7396L6.16954 6.90625L4.99121 8.08458L9.99954 13.0938Z"
        fill="#7C88A6"
      />
    </svg>
  ),
  assistance: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 6L10.5 10.5M6 6H3L2 3L3 2L6 3V6ZM19.259 2.74101L16.6314 5.36863C16.2354 5.76465 16.0373 5.96265 15.9632 6.19098C15.8979 6.39183 15.8979 6.60817 15.9632 6.80902C16.0373 7.03735 16.2354 7.23535 16.6314 7.63137L16.8686 7.86863C17.2646 8.26465 17.4627 8.46265 17.691 8.53684C17.8918 8.6021 18.1082 8.6021 18.309 8.53684C18.5373 8.46265 18.7354 8.26465 19.1314 7.86863L21.5893 5.41072C21.854 6.05488 22 6.76039 22 7.5C22 10.5376 19.5376 13 16.5 13C16.1338 13 15.7759 12.9642 15.4298 12.8959C14.9436 12.8001 14.7005 12.7521 14.5532 12.7668C14.3965 12.7824 14.3193 12.8059 14.1805 12.8802C14.0499 12.9501 13.919 13.081 13.657 13.343L6.5 20.5C5.67157 21.3284 4.32843 21.3284 3.5 20.5C2.67157 19.6716 2.67157 18.3284 3.5 17.5L10.657 10.343C10.919 10.081 11.0499 9.95005 11.1198 9.81949C11.1941 9.68068 11.2176 9.60347 11.2332 9.44681C11.2479 9.29945 11.1999 9.05638 11.1041 8.57024C11.0358 8.22406 11 7.86621 11 7.5C11 4.46243 13.4624 2 16.5 2C17.5055 2 18.448 2.26982 19.259 2.74101ZM12.0001 14.9999L17.5 20.4999C18.3284 21.3283 19.6716 21.3283 20.5 20.4999C21.3284 19.6715 21.3284 18.3283 20.5 17.4999L15.9753 12.9753C15.655 12.945 15.3427 12.8872 15.0408 12.8043C14.6517 12.6975 14.2249 12.7751 13.9397 13.0603L12.0001 14.9999Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  bill: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5749 6.75H4.42488C4.24033 6.75087 4.06242 6.81891 3.92439 6.94141C3.78636 7.06391 3.69766 7.23249 3.67488 7.41562L2.34363 19.4156C2.33179 19.5202 2.34208 19.6261 2.37384 19.7264C2.4056 19.8267 2.45811 19.9192 2.52796 19.9979C2.59781 20.0766 2.68344 20.1397 2.77928 20.1831C2.87513 20.2266 2.97903 20.2494 3.08426 20.25H20.9155C21.0207 20.2494 21.1246 20.2266 21.2205 20.1831C21.3163 20.1397 21.4019 20.0766 21.4718 19.9979C21.5416 19.9192 21.5942 19.8267 21.6259 19.7264C21.6577 19.6261 21.668 19.5202 21.6561 19.4156L20.3249 7.41562C20.3021 7.23249 20.2134 7.06391 20.0754 6.94141C19.9373 6.81891 19.7594 6.75087 19.5749 6.75Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 9.75V6.75C8.25 5.75544 8.64509 4.80161 9.34835 4.09835C10.0516 3.39509 11.0054 3 12 3C12.9946 3 13.9484 3.39509 14.6517 4.09835C15.3549 4.80161 15.75 5.75544 15.75 6.75V9.75"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  doubleArrow: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 13L7 7L13 1"
        stroke="#E5101D"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13L1 7L7 1"
        stroke="#E5101D"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  multipleOrder: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6668 8.75008V5.66675C16.6668 4.26662 16.6668 3.56655 16.3943 3.03177C16.1547 2.56137 15.7722 2.17892 15.3018 1.93923C14.767 1.66675 14.067 1.66675 12.6668 1.66675H7.3335C5.93336 1.66675 5.2333 1.66675 4.69852 1.93923C4.22811 2.17892 3.84566 2.56137 3.60598 3.03177C3.3335 3.56655 3.3335 4.26662 3.3335 5.66675V14.3334C3.3335 15.7335 3.3335 16.4336 3.60598 16.9684C3.84566 17.4388 4.22811 17.8212 4.69852 18.0609C5.2333 18.3334 5.93336 18.3334 7.3335 18.3334H10.0002M11.6668 9.16675H6.66683M8.3335 12.5001H6.66683M13.3335 5.83341H6.66683M15.0002 17.5001V12.5001M12.5002 15.0001H17.5002"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  overview: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.126 20.2054V15.4371C14.126 15.2264 14.0423 15.0242 13.8932 14.8752C13.7442 14.7261 13.542 14.6424 13.3313 14.6424H10.1524C9.94165 14.6424 9.73951 14.7261 9.59047 14.8752C9.44143 15.0242 9.3577 15.2264 9.3577 15.4371V20.2054C9.3577 20.4162 9.27398 20.6183 9.12494 20.7674C8.9759 20.9164 8.77376 21.0001 8.56299 21.0001H3.79471C3.58394 21.0001 3.3818 20.9164 3.23277 20.7674C3.08373 20.6183 3 20.4162 3 20.2054V11.0165C3.00178 10.9066 3.02555 10.798 3.0699 10.6974C3.11426 10.5967 3.17831 10.506 3.25828 10.4304L11.2054 3.20848C11.3519 3.07445 11.5433 3.00012 11.7418 3.00012C11.9404 3.00012 12.1318 3.07445 12.2783 3.20848L20.2254 10.4304C20.3054 10.506 20.3694 10.5967 20.4138 10.6974C20.4581 10.798 20.4819 10.9066 20.4837 11.0165V20.2054C20.4837 20.4162 20.4 20.6183 20.2509 20.7674C20.1019 20.9164 19.8997 21.0001 19.689 21.0001H14.9207C14.7099 21.0001 14.5078 20.9164 14.3587 20.7674C14.2097 20.6183 14.126 20.4162 14.126 20.2054Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  partnerManager: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5673 11.4173L20.25 12.576L17.25 6.83079L19.5909 5.66036C19.7667 5.57242 19.9701 5.55707 20.1572 5.61761C20.3443 5.67814 20.5001 5.80973 20.5911 5.98403L22.8967 10.3994C22.9428 10.4877 22.9708 10.5842 22.9792 10.6835C22.9876 10.7827 22.9761 10.8827 22.9454 10.9774C22.9148 11.0722 22.8655 11.1599 22.8006 11.2354C22.7357 11.311 22.6564 11.3728 22.5673 11.4173V11.4173Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75042 12.4755L1.43314 11.3169C1.34406 11.2723 1.26474 11.2105 1.19981 11.135C1.13488 11.0594 1.08565 10.9717 1.05498 10.877C1.02432 10.7822 1.01284 10.6823 1.02121 10.583C1.02958 10.4838 1.05763 10.3872 1.10374 10.2989L3.40932 5.88357C3.50034 5.70927 3.65616 5.57768 3.84323 5.51714C4.03031 5.45661 4.23368 5.47196 4.40955 5.55989L6.75042 6.73033L3.75042 12.4755Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 12.5759L18.75 14.3308L15.3003 17.7805C15.2087 17.8721 15.095 17.9384 14.9701 17.9729C14.8453 18.0075 14.7137 18.0092 14.588 17.9778L9.15458 16.6194C9.05266 16.5939 8.95724 16.5473 8.87448 16.4826L3.75 12.4755"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7498 14.3308L14.6248 11.3308L13.4248 12.2308C12.9055 12.6203 12.2739 12.8308 11.6248 12.8308C10.9757 12.8308 10.3441 12.6203 9.8248 12.2308L9.3166 11.8497C9.23064 11.7852 9.15953 11.703 9.1081 11.6087C9.05667 11.5143 9.02611 11.41 9.01849 11.3028C9.01087 11.1956 9.02637 11.0881 9.06395 10.9874C9.10152 10.8867 9.16029 10.7953 9.23627 10.7193L12.9051 7.05048C12.9748 6.98084 13.0574 6.92559 13.1484 6.8879C13.2394 6.85021 13.337 6.83081 13.4355 6.83081H17.2498"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.80371 6.73035L11.615 5.3277C11.7868 5.2776 11.971 5.29062 12.1341 5.36442L15.3749 6.83083"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 19.9558L7.67443 19.2494C7.55977 19.2207 7.4535 19.1654 7.3643 19.0878L5.25 17.25"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  product: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.25 17.25H6.54375L3.92813 2.86875C3.89752 2.69653 3.80768 2.54042 3.67415 2.42743C3.54062 2.31444 3.37179 2.25168 3.19687 2.25H1.5"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 21C8.53553 21 9.375 20.1605 9.375 19.125C9.375 18.0895 8.53553 17.25 7.5 17.25C6.46447 17.25 5.625 18.0895 5.625 19.125C5.625 20.1605 6.46447 21 7.5 21Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 21C18.2855 21 19.125 20.1605 19.125 19.125C19.125 18.0895 18.2855 17.25 17.25 17.25C16.2145 17.25 15.375 18.0895 15.375 19.125C15.375 20.1605 16.2145 21 17.25 21Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.85938 13.5H17.6344C17.985 13.5011 18.3247 13.3785 18.5939 13.1539C18.8631 12.9293 19.0445 12.617 19.1063 12.2719L20.25 6H4.5"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  report: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 13V17M16 11V17M12 7V17M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  saleChanel: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeMiterlimit="10"
      />
      <path
        d="M3.51562 9H20.4844"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.51562 15H20.4844"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20.7566C14.0711 20.7566 15.75 16.8363 15.75 12.0004C15.75 7.16445 14.0711 3.24414 12 3.24414C9.92893 3.24414 8.25 7.16445 8.25 12.0004C8.25 16.8363 9.92893 20.7566 12 20.7566Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeMiterlimit="10"
      />
    </svg>
  ),
  separateAddress: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 10.625C11.3807 10.625 12.5 9.50571 12.5 8.125C12.5 6.74429 11.3807 5.625 10 5.625C8.61929 5.625 7.5 6.74429 7.5 8.125C7.5 9.50571 8.61929 10.625 10 10.625Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.63672 2.0498L16.1367 15.0498"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.57903 3.13057C8.32663 2.76822 9.15325 2.5748 9.9998 2.5748C11.4718 2.5748 12.8834 3.15953 13.9242 4.20036C14.9651 5.24119 15.5498 6.65285 15.5498 8.1248C15.5498 9.18327 15.3053 10.2039 14.9109 11.1607L15.9405 12.2884C16.5434 11.0298 16.9498 9.62565 16.9498 8.1248C16.9498 6.28155 16.2176 4.51379 14.9142 3.21041C13.6108 1.90703 11.8431 1.1748 9.9998 1.1748C8.79973 1.1748 7.63166 1.48518 6.60291 2.06149L7.57903 3.13057ZM5.85084 4.43852L4.92311 3.37825C3.72141 4.66346 3.0498 6.35951 3.0498 8.1248C3.0498 11.1893 4.74401 13.8505 6.34658 15.6935C7.15642 16.6248 7.96445 17.3721 8.56989 17.8867C8.87309 18.1444 9.12677 18.3449 9.30618 18.482C9.39592 18.5506 9.46718 18.6034 9.51689 18.6397C9.54174 18.6578 9.56121 18.6718 9.57492 18.6816L9.59112 18.6932L9.59588 18.6965L9.59742 18.6976L9.59797 18.698C9.59819 18.6981 9.59838 18.6983 9.9998 18.1248L9.59838 18.6983C9.83941 18.867 10.1602 18.867 10.4012 18.6983L9.9998 18.1248C10.4012 18.6983 10.4014 18.6981 10.4016 18.698L10.4022 18.6976L10.4037 18.6965L10.4085 18.6932L10.4247 18.6816C10.4384 18.6718 10.4579 18.6578 10.4827 18.6397C10.5324 18.6034 10.6037 18.5506 10.6934 18.482C10.8728 18.3449 11.1265 18.1444 11.4297 17.8867C12.0352 17.3721 12.8432 16.6248 13.653 15.6935C13.9793 15.3183 14.3093 14.9092 14.6281 14.4697L13.6802 13.3863C13.3377 13.8809 12.9699 14.3456 12.5966 14.7749C11.8439 15.6404 11.0895 16.3385 10.523 16.82C10.3212 16.9915 10.1438 17.1351 9.9998 17.2483C9.85578 17.1351 9.67845 16.9915 9.4766 16.82C8.91015 16.3385 8.15569 15.6404 7.40303 14.7749C5.8806 13.0241 4.4498 10.6853 4.4498 8.1248C4.4498 6.76228 4.95083 5.45142 5.85084 4.43852Z"
        fill="#7C88A6"
      />
    </svg>
  ),
  support: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 6L10.5 10.5M6 6H3L2 3L3 2L6 3V6ZM19.259 2.74101L16.6314 5.36863C16.2354 5.76465 16.0373 5.96265 15.9632 6.19098C15.8979 6.39183 15.8979 6.60817 15.9632 6.80902C16.0373 7.03735 16.2354 7.23535 16.6314 7.63137L16.8686 7.86863C17.2646 8.26465 17.4627 8.46265 17.691 8.53684C17.8918 8.6021 18.1082 8.6021 18.309 8.53684C18.5373 8.46265 18.7354 8.26465 19.1314 7.86863L21.5893 5.41072C21.854 6.05488 22 6.76039 22 7.5C22 10.5376 19.5376 13 16.5 13C16.1338 13 15.7759 12.9642 15.4298 12.8959C14.9436 12.8001 14.7005 12.7521 14.5532 12.7668C14.3965 12.7824 14.3193 12.8059 14.1805 12.8802C14.0499 12.9501 13.919 13.081 13.657 13.343L6.5 20.5C5.67157 21.3284 4.32843 21.3284 3.5 20.5C2.67157 19.6716 2.67157 18.3284 3.5 17.5L10.657 10.343C10.919 10.081 11.0499 9.95005 11.1198 9.81949C11.1941 9.68068 11.2176 9.60347 11.2332 9.44681C11.2479 9.29945 11.1999 9.05638 11.1041 8.57024C11.0358 8.22406 11 7.86621 11 7.5C11 4.46243 13.4624 2 16.5 2C17.5055 2 18.448 2.26982 19.259 2.74101ZM12.0001 14.9999L17.5 20.4999C18.3284 21.3283 19.6716 21.3283 20.5 20.4999C21.3284 19.6715 21.3284 18.3283 20.5 17.4999L15.9753 12.9753C15.655 12.945 15.3427 12.8872 15.0408 12.8043C14.6517 12.6975 14.2249 12.7751 13.9397 13.0603L12.0001 14.9999Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  transport: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 11.25H16.5V7.5H20.4922C20.6421 7.5 20.7886 7.54491 20.9127 7.62895C21.0368 7.71298 21.1329 7.83228 21.1886 7.97146L22.5 11.25Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 13.5H16.5"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.625 20.25C18.8676 20.25 19.875 19.2426 19.875 18C19.875 16.7574 18.8676 15.75 17.625 15.75C16.3824 15.75 15.375 16.7574 15.375 18C15.375 19.2426 16.3824 20.25 17.625 20.25Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.375 20.25C7.61764 20.25 8.625 19.2426 8.625 18C8.625 16.7574 7.61764 15.75 6.375 15.75C5.13236 15.75 4.125 16.7574 4.125 18C4.125 19.2426 5.13236 20.25 6.375 20.25Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.375 18H8.625"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.125 18H2.25C2.05109 18 1.86032 17.921 1.71967 17.7803C1.57902 17.6397 1.5 17.4489 1.5 17.25V6.75C1.5 6.55109 1.57902 6.36032 1.71967 6.21967C1.86032 6.07902 2.05109 6 2.25 6H16.5V16.0514"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 16.0514V11.25H22.5V17.25C22.5 17.4489 22.421 17.6397 22.2803 17.7803C22.1397 17.921 21.9489 18 21.75 18H19.875"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  warehouse: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.80005L2 8.40005H22L12 2.80005Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
      <path
        d="M3.59961 8.39966V21.1997H20.3996V8.39966"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
      <path
        d="M7 21.0005V11.0005H17V21.0005"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.0001H14"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 17.0004H10"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  shippingTracking: (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 3.4375H12.649C12.8478 3.4375 12.9471 3.4375 13.0406 3.45995C13.1235 3.47985 13.2028 3.51268 13.2755 3.55723C13.3575 3.60748 13.4277 3.67774 13.5683 3.81826L16.8692 7.11924C17.0098 7.25976 17.08 7.33002 17.1303 7.41202C17.1748 7.48471 17.2076 7.56397 17.2276 7.64687C17.25 7.74038 17.25 7.83975 17.25 8.03848V10.3438C17.25 10.7223 17.25 10.9116 17.1882 11.0609C17.1057 11.26 16.9475 11.4182 16.7484 11.5007C16.5991 11.5625 16.4098 11.5625 16.0312 11.5625M11.9688 11.5625H10.75M10.75 11.5625V3.6C10.75 2.68991 10.75 2.23487 10.5729 1.88727C10.4171 1.5815 10.1685 1.33291 9.86273 1.17711C9.51513 1 9.06009 1 8.15 1H3.6C2.68991 1 2.23487 1 1.88727 1.17711C1.5815 1.33291 1.33291 1.5815 1.17711 1.88727C1 2.23487 1 2.68991 1 3.6V9.9375C1 10.835 1.72754 11.5625 2.625 11.5625M10.75 11.5625H7.5M7.5 11.5625C7.5 12.9087 6.40869 14 5.0625 14C3.71631 14 2.625 12.9087 2.625 11.5625M7.5 11.5625C7.5 10.2163 6.40869 9.125 5.0625 9.125C3.71631 9.125 2.625 10.2163 2.625 11.5625M16.0312 11.9688C16.0312 13.0906 15.1218 14 14 14C12.8782 14 11.9688 13.0906 11.9688 11.9688C11.9688 10.8469 12.8782 9.9375 14 9.9375C15.1218 9.9375 16.0312 10.8469 16.0312 11.9688Z"
        stroke="#7C88A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  codManager: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 17.4C3 19.0802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 19.0802 21 17.4M21 6.6C21 4.91984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 4.91984 3 6.6"
        stroke="#7C88A6"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66244 15.9242C6.42129 15.9242 7.69632 14.8206 7.83743 13.2734L7.84247 13.2179H6.36081L6.35073 13.2683C6.15418 14.1301 5.53935 14.6341 4.66244 14.6341C3.49828 14.6341 2.75241 13.6866 2.75241 12.1546V12.1495C2.75241 10.6276 3.49828 9.69018 4.66244 9.69018C5.56958 9.69018 6.16426 10.2143 6.33561 11.0156L6.35577 11.1013H7.83743L7.83239 11.0559C7.69128 9.51379 6.42633 8.40002 4.66244 8.40002C2.54075 8.40002 1.2002 9.81113 1.2002 12.1546V12.1596C1.2002 14.5031 2.53571 15.9242 4.66244 15.9242Z"
        fill="#7C88A6"
      />
      <path
        d="M12.0657 15.9242C14.2277 15.9242 15.5733 14.4779 15.5733 12.1647V12.1546C15.5733 9.84641 14.2227 8.40002 12.0657 8.40002C9.91377 8.40002 8.5581 9.84137 8.5581 12.1546V12.1647C8.5581 14.4779 9.90369 15.9242 12.0657 15.9242ZM12.0657 14.6341C10.8713 14.6341 10.1103 13.6866 10.1103 12.1647V12.1546C10.1103 10.6326 10.8763 9.69018 12.0657 9.69018C13.2601 9.69018 14.0211 10.6376 14.0211 12.1546V12.1647C14.0211 13.6715 13.2752 14.6341 12.0657 14.6341Z"
        fill="#7C88A6"
      />
      <path
        d="M16.5561 15.7983H19.3329C21.5151 15.7983 22.8002 14.4426 22.8002 12.1344V12.1243C22.8002 9.82121 21.51 8.52602 19.3329 8.52602H16.5561V15.7983ZM18.078 14.5383V9.78089H19.1515C20.487 9.78089 21.248 10.6124 21.248 12.1294V12.1395C21.248 13.7118 20.5122 14.5383 19.1515 14.5383H18.078Z"
        fill="#7C88A6"
      />
    </svg>
  ),
}
