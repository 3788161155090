const config = {
  env: "DEV",
  PHONE: "1900 1511",
  REACT_APP_FACEBOOK_APP_ID: "617831916462959",
  REACT_APP_FACEBOOK_APP_SECRET: "3d1df66548c37f3e7c8c396cbb32fcc5",
  // env: "PROD"
  API: "https://api-dev.evoshop.vn/api/v3",
  API_V2: 'https://api-dev.upos.vn/api/v2',
  API_FB: 'https://api-node.upos.vn',
  WS: 'https://hook.evoshop.vn',
  HREF: 'https://test.evoshop.vn',
  TRACKING_ID : 'G-YD8GWXCMZ2',
  CSKH: '0916967849',
  CHAT_CSKH: "https://demoimc.jtexpress.vn/kqpc?code=EVOSHOP",
  REACT_APP_GOOOGLE_APP_ID: "1080585093342-bkkkjd535aah6m4p7vikjji6tmvenngc.apps.googleusercontent.com",
  REACT_APP_GOOLE_APP_SECRET: "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",
}
export default config;

