import React, {createContext, useReducer, useContext, useEffect, useState} from 'react'

// import Navigation from './Component/SideMenu/SideMenu';
import Navigation from 'Component/NavBar/navBar'
import { initialSettingState } from './Pages/Setting/stores/initState'
import SettingReducer from './Pages/Setting/stores/reducer'
import {getData, sendRequest, sendRequestAuth} from './api/api'
import { getUrlStoreSetting } from './api/url'
import useGlobalContext from '../src/containerContext/storeContext'
import {formatMoney, generateTokenDevices, getCookie, setCookie} from 'util/functionUtil'
import config from "./config";
import ModalNotification from "./Component/modalNotification";

export const SettingContext = createContext(initialSettingState)
const SettingStore = ({ children }) => {
  const [state, dispatch] = useReducer(SettingReducer, initialSettingState)
  return (
    <SettingContext.Provider value={[state, dispatch]}>
      {children}
    </SettingContext.Provider>
  )
}

const LayoutWrapper = (params) => {
  const [state, dispatch] = useGlobalContext()
  const [openModal, setOpenModal] = useState(false)
  const [detailNotice,setDetailNotice] = useState({})

  const getDataStoreSetting = () => {
    const url = getUrlStoreSetting()
    sendRequest('get', url)
      .then(res => {
        if (res && res.data && res.data.success) {
          const data = res.data.data || []
          dispatch({ type: 'GET_STORE_INFO', payload: data })
        } else {
        }
      })
      .catch(error => {
        console.log(`error${error}`)
      })
  }
  const closeModal = ()=>{
    setOpenModal(false)
    fetchAfterCloseModal()
  }
  const fetchAfterCloseModal = async ()=>{
    const res = await sendRequest('get',`${config.API}/notices/list?per_page=20&start=0&type=0`)
    if (res.data.success) {
      const filterData = res.data?.data?.filter(filter => +filter?.is_popup === 0 || +filter?.is_read === 1  )
      dispatch({
        type: 'NOTICE_TOTAL_UNREAD', payload: {
          all: filterData?.filter(filter => +filter?.is_read === 0).length,
          notice:{
            allList:{
              list:filterData,
              total: res.data.meta.total
            },
          }
        }
      })
    }
  }
  const checkDateTime = (start, end)=>{
    const startExprite = new Date(start)
    const endExprite = new Date(end)
    const date = new Date()
    if(startExprite?.getTime() <= date?.getTime() && endExprite?.getTime() >= date?.getTime()) return true
    return false
  }

  const checkIsPopup =  async (data)=>{
    const response = await sendRequestAuth('get', `${config.API}/notices/detail/${data?.id}`)
    if(response?.data?.success){
      setOpenModal(true)
      setDetailNotice(response?.data?.data)
    }
  }
  const fetchNotification = async ()=>{
    const res = await sendRequest('get',`${config.API}/notices/list?per_page=20&start=0&type=0`)
    if (res.data.success) {
      const filterData = res.data?.data?.filter(filter => +filter?.is_popup === 0 || +filter?.is_read === 1  )
      const findData =  res.data?.data?.find(find =>{
        const checkDate = checkDateTime(find?.started_at, find?.ended_at)
        if(+find?.is_popup === 1 && +find?.is_read === 0 && !!checkDate) return find
      })
      if(!!findData) checkIsPopup(findData)
      dispatch({
        type: 'NOTICE_TOTAL_UNREAD', payload: {
          all: filterData?.filter(filter => +filter?.is_read === 0).length,
          notice:{
            allList:{
              list:filterData,
              total: res.data.meta.total
            },
          }
        }
      })
    }
  }
  useEffect(() => {
    const pathNames = [
        '/',
        '/login',
        '/admin'
    ].includes(location.pathname)
    getDataStoreSetting()
    if(!pathNames ) fetchNotification()
    let clientToken = getCookie('client_token')
    clientToken = !!clientToken ? clientToken : generateTokenDevices()
    location.host == 'localhost:3000'
      ? setCookie({ cname: 'client_token', cvalue: clientToken, exdays: 180, domain: '' })
      : setCookie({ cname: 'client_token', cvalue: clientToken, exdays: 180, domain: ';domain=.evoshop.vn' }) //tam thoi mot thay lai evoshop

  }, [])


  

  return (
    <div className="App">
      <SettingStore>
        <Navigation/>
        {openModal && <ModalNotification openModal={openModal} setOpenModal={closeModal} item={detailNotice}/>}
      </SettingStore>
    </div>
  )
}
export default LayoutWrapper

export const formatShippingFee = ( shipFee = 0) => {
  const [state] = useGlobalContext()
  const manage = state?.user?.is_manage || 0
  const shippingConfig = !!state?.shopInfo?.shipping_config ? state?.shopInfo?.shipping_config[1] : null
  const hidden_fee = shippingConfig?.hidden_shipfee

  if( +manage === 1) return formatMoney(shipFee || 0)
  else return +hidden_fee === 1 ? '*****₫' : formatMoney(shipFee || 0)
}
