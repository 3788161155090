import styled from 'styled-components'

export const StyledGuideScreen = styled.div`

    width: 100%;
    height: 100%;
    padding: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .guide-screen-confirm{
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 100%;
        text-align: center;
        &_frame{
            top: 35%;
            position: relative;
            >img{
              width: 200px;
            }
            &-welcome{
              width: 100%;
              display: flex;
              justify-content: center;
              p{
                margin-top: 24px;
                width: 414px!important;
                font-style: italic!important;
              }
            }
        }
        &_text {
            margin-top: 24px;
            margin-bottom: 24px;
            &_tutorial {
                font-weight: 600;
                margin: 0 auto;
                color: #FFFFFF !important;
                text-decoration:underline;
            }
        }
        &_tutorial_step1 {
            left: 150px;
            position: absolute;
            bottom: 43px;
            width: 300px;
            &_minimize {
                left: 56px;
            }
            &_frame {
                margin: 0 auto !important;
                width: 300px !important;
            }
            &_text{
              margin-bottom: 8px;
              text-align: center;
            }
        }
        &_tutorial_step2 {
            left: 215px;
            position: absolute;
            top: 37%;
            width: 290px;
            &_minimize {
                left: 56px;
            }
            &_frame {
                margin-left: 7px !important;
                width: 300px !important;
            }
            &_text{
              text-align: center;
            }
        }
        
        &_tutorials_frame {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: right;

            &_step3 {
                width: 253px;
                margin-top: 32px;
                &_frame {
                    margin: 0 auto !important;
                }
                &_text {
                    width: max-content !important;
                    margin-left: -124px;
                    img{
                        margin-top: -12px;
                    }
                    text-align: center;
                }
            }
            &_step4 {
                width: 90px;
                margin-top: 32px;
                margin-right: 40px;
                &_frame {
                    margin: 0 auto !important;
                }
                &_text {
                    width: 185px!important;
                    margin-left: -84px;
                    img {
                        margin-top: -9px;
                    }
                    text-align: center;
                }
            }
            &_step5 {
                margin-top: 12px;    
                margin-left: 10.5em;
                margin-right: 6em;
                max-width: 12rem;
                &_1{
                    text-indent: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                }
    
                &_frame {
                    margin: 0 auto !important;
                }
                &_text {
                    width: 212px !important;
                    img {
                        margin-top: -12px;
                    }
                    text-align: center;
                }
            }
        }
        &_tutorials_img {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: right;

            &_step3 {
                width: 253px;
                &_text {
                    width: max-content !important;
                    margin-left: -124px;
                    img{
                        margin-top: -12px;
                    }
                }
            }
            &_step4 {
                &_text {
                    width: 185px !important;
                    margin-left: -84px;
                    img {
                        margin-top: -9px;
                    }
                    text-align: center;
                }
            }
            &_step5 {
                margin-top: 2px;    
                margin-left: 10.5em;
                &_text {
                    width: 212px !important;
                    img {
                        margin-top: -12px;
                    }
                    text-align: center;
                }
            }
        }
        
        &_tutorial_step6 {
            right: 41px;
            position: absolute;
            bottom: 165px;
            width: 306px;
            &_frame {
                margin-right: -235px;
                width: 300px !important;
                float: right;
            }
            &_text{
              width: 300px !important;
              text-align: center;
            }
        }
        &_tutorial_step7 {
            right: 838px;
            position: absolute;
            top: 48px;
            &_minimize {
                right: 710px;
                top:25px;
            }
            &1_frame {
                margin-right: -235px;
                width: 300px !important;
                float: right;
            }
            &2_frame {
                float: left;
                margin-top:70px;
                margin-right: 12px;
            }
            &_text {
                img {
                    margin-top:27px
                }
                width: 414px!important;
                margin-top: 24px;
            }
        }
        &_tutorial_step8 {
            right: 422px;
            position: absolute;
            top: 255px;
            &_minimize {
                right: 325px;
                top: 177px;
            }
            &_frame {
                margin-left: 170px;
            }
            &_text {
                width: 150px!important;
                img {
                    margin-right: 94px;
                    margin-top: -15px;
                }
                margin-left: 26px;
            }
        }
        &_tutorial_step9 {
            right: 422px;
            position: absolute;
            bottom: 55px;
            &_minimize {
                right: 325px;
            }
            &_frame {
                margin-left: 224px;
            }
            &_text {
                img {
                    margin-right: 94px;
                    margin-top: -15px;
                }
                width: 240px!important;
            }
        }
        &_next_start {
          margin: 44px 0 16px 0;
            &_text {
                float: left;
            }
            img {
                margin: 11px 0 0 7px;
            }
        }

        &_next_tutorial {
          margin: 44px 24px 16px 0 ;
          color: #E5101D!important;
          background: #fff!important;
        }
    }
    @media screen and (max-width: 1366px) {
        .guide-screen-confirm_tutorials_frame_step3 , .guide-screen-confirm_tutorials_frame_step4 {
            margin-top: 23px;
        }
        .guide-screen-confirm_tutorials_frame_step5 {
            margin-top: 2px;
        }
    }
`
