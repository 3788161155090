import {getData, sendRequestAuth} from 'api/api'
import {getUrlLogOut} from 'api/url'
import useGlobalContext from 'containerContext/storeContext'
import {PATH} from 'const/path'
import {useNavigate} from 'react-router-dom'
import config from 'config'
import useAlert from 'hook/useAlert'
import {useEffect, useState, useSyncExternalStore} from 'react'
import {useTranslation} from 'react-i18next'
import {Text} from "../../../common/text";

const getSnapshot = () => localStorage.getItem('device_token')

const subscribe = listener => {
   window.addEventListener('storage', listener)
   return () => {
      window.removeEventListener('storage', listener)
   }
}

export default function useCustomerAccount() {
   const {t} = useTranslation()
   const nav = useNavigate()
   const {showAlert} = useAlert()
   const [state, dispatch] = useGlobalContext()
   const {shopInfo, user, userInfoPopup} = state

   const deviceTokenList = useSyncExternalStore(subscribe, getSnapshot)
   const setDeviceTokenList = value => {

      const newValue = JSON.stringify(value)
      const originOrderActive = JSON.parse(
         window.localStorage.getItem('origin_order_active'),
      )
      const configTableOrder = JSON.parse(
         window.localStorage.getItem('config_table_order'),
      )
      const configTableDelivery = JSON.parse(
         window.localStorage.getItem('config_table_delivery'),
      )

      const lang = window.localStorage.getItem('i18n') || 'vi'
      window.localStorage.clear()
      window.localStorage.setItem('i18n', lang)
      if (!!originOrderActive && Array.isArray(originOrderActive))
         window.localStorage.setItem(
            'origin_order_active',
            JSON.stringify(originOrderActive),
         )
      if (!!configTableOrder && Array.isArray(configTableOrder))
         window.localStorage.setItem(
            'config_table_order',
            JSON.stringify(configTableOrder),
         )
      if (!!configTableDelivery && Array.isArray(configTableDelivery))
         window.localStorage.setItem(
            'config_table_delivery',
            JSON.stringify(configTableDelivery),
         )
      window.localStorage.setItem('device_token', newValue)
      window.dispatchEvent(
         new StorageEvent('storage', {key: 'device_token', newValue}),
      )

      window.localStorage.setItem('switch_account', true)
   }


   const switchAccount = async account => {
      const response = await sendRequestAuth(
         'post',
         `${config.API}/auth/switch-account`,
         {
            client_id: 'evoshop',
            client_version: '',
            user_id: account?.user_id,
            client_device_token: account?.client_device_token,
         },
      )
      if (response.data.success) {
         let deviceToken = JSON.parse(window.localStorage.getItem('device_token'))
         deviceToken = deviceToken.filter(item => item.user_id !== account.user_id)
         deviceToken = [account, ...deviceToken]
         setDeviceTokenList(deviceToken || [])
         showAlert({
            type: 'success',
            content: t('formLogin_Accessing'),
         })
         window.location.href = '/admin'
      } else {
         showAlert({
            type: 'danger',
            content: response.data?.message || t('formLogin_switch_account_error'),
         })
      }
   }

   const addNewAccount = () => {

      let deviceToken = JSON.parse(window.localStorage.getItem('device_token'))
      setDeviceTokenList(deviceToken || [])

      const url = getUrlLogOut()
      getData(url)
         .then(() => {
            dispatch({type: 'SET_LOGOUT'})
            dispatch({type: 'SET_USER', payload: {}})
            nav(PATH.LOGIN, {state: {addNew: true}})
         })
         .catch(() => {
            console.log('error logout')
         })
   }

   const logoutAccount = async account => {
      const isCurrentAccount = user?.user_id === account?.user_id
      if (isCurrentAccount) handleLogoutCurrentAccount(account)
      else handleLogoutAccount(account)
   }

   const handleLogoutAccount = async account => {
      let deviceToken = JSON.parse(window.localStorage.getItem('device_token'))
      deviceToken = deviceToken.filter(item => item.user_id !== account.user_id)
      setDeviceTokenList(deviceToken || [])
      showAlert({
         type: 'success',
         content: `${t('formLogin_Logout_success')}  ${account?.shop?.vip_code_jnt} ${t('success')}`
      })
   }

   const handleLogoutCurrentAccount = () => {
      let deviceToken = JSON.parse(window.localStorage.getItem('device_token'))
      deviceToken = deviceToken?.filter(item => item.user_id !== user.user_id)
      setDeviceTokenList(deviceToken || [])

      const url = getUrlLogOut()
      getData(url)
         .then(() => {
            dispatch({type: 'SET_LOGOUT'})
            dispatch({type: 'SET_USER', payload: {}})
            nav(PATH.LOGIN, {state: {addNew: false}})
         })
         .catch(() => {
            console.log('error logout')
         })
   }

   const logoutAllAccount = async () => {
      setDeviceTokenList([])
      handleLogoutCurrentAccount()
   }

   return {
      handleLogoutCurrentAccount,
      addNewAccount,
      switchAccount,
      logoutAccount,
      logoutAllAccount,
      deviceTokenList: !!deviceTokenList ? JSON.parse(deviceTokenList) : [],
   }
}
