import React, { lazy } from "react"
import { PATH } from '../const/path'

const InventoryInformation = lazy(() => import('../Pages/inventoryInformation'));
const WareHouseTransfer = lazy(() => import('../Pages/WareHouseTransfer'));
const WarehouseManagement = lazy(() => import('../Pages/WarehouseManagement'));
const CreateWarehouseTransfer = lazy(() => import('../Pages/CreateWarehouseTransfer'));
const PurchasesManagement = lazy(() => import('../Pages/purchases'));
const CreatePurchases = lazy(() => import('../Pages/purchases/create'));
const EditPurchases = lazy(() => import('../Pages/purchases/create/edit'));
const RefundPurchases = lazy(() => import('../Pages/purchases/create/refund'));
const PageFacebookFanpage = lazy(() => import('../Pages/facebookManament/pages/fanpage'));
const PageFacebookResponseContentScript = lazy(() => import('../Pages/facebookManament/pages/responseContentScript'));
const PageFacebookHideAutoComment = lazy(() => import('../Pages/facebookManament/pages/hideAutoComment'));
const PageFacebookAutoResponses = lazy(() => import('../Pages/facebookManament/pages/autoResponses'));
const CreateAutoResponses = lazy(() => import('../Pages/facebookManament/pages/autoResponses/create'));
const CreateAutoMessage = lazy(() => import('../Pages/facebookManament/pages/autoResponsesMessage/create'));
const PageFacebookConversationStickers = lazy(() => import('../Pages/facebookManament/pages/conversationStickers'));
const PageFacebookLivestreamScript = lazy(() => import('../Pages/facebookManament/pages/livestreamScript'));
const PageFacebookLivestreamScriptSingle = lazy(() => import('../Pages/facebookManament/pages/livestreamScript/single'));
const PrintConfig = lazy(() => import('../Pages/facebookManament/pages/printConfig'));
const FacebookConversation = lazy(() => import('../Pages/facebookManament/pages/conversation'));
const FacebookOrders = lazy(() => import('../Pages/facebookManament/pages/orders'));
const FacebookLivestream = lazy(() => import('../Pages/facebookManament/pages/livestream'));
const FacebookLiveStreamDetail = lazy(() => import('../Pages/facebookManament/pages/livestreamDetail'));
const Customer = lazy(() => import('../Pages/customer'));
const CreateCustomer = lazy(() => import('../Pages/customer/CreateCustomer/createCustomer'));
const EditCustomer = lazy(() => import('../Pages/customer/EditCustomer'));
const SupplierManagement = lazy(() => import('../Pages/supplierManagement'));
const ShippingPartner = lazy(() => import('../Pages/ShippingPartner'));
const PageBulkOrder = lazy(() => import('../Pages/bulkOrder'));
const PageBulkOrderCreate = lazy(() => import('../Pages/bulkOrder/pages/create'));
const PageBulkOrderDetail = lazy(() => import('../Pages/bulkOrder/pages/bulkOrderDetail'));
const ShippingTracking = lazy(() => import('../Pages/shippingTracking'));
const DeliveryManagement = lazy(() => import('../Pages/deliveryManagement'));
const PartSign = lazy(() => import('../Pages/partSign'));
const ComingSoon = lazy(() => import('../Pages/comingSoon'));
const CodManagement = lazy(() => import('../Pages/CodManagement'));
const ShippingTrackingManagement = lazy(() => import('../Pages/shippingTrackingManagement'));
const DeliveryOverview = lazy(() => import('../Pages/deliveryOverview'));
const CreateProduct = lazy(() => import('../Pages/product/createProduct'));
const RefactorOrder = lazy(() => import('../Pages/refactorOrder'));
const OrderOrigin = lazy(() => import('../Pages/OrderOrigin'));
const PageOrderSingle = lazy(() => import('../Pages/orderSingle'));
const PageEditOrderSingle = lazy(() => import('../Pages/orderSingle/editOrder'));
const ProductGroup = lazy(() => import('../Pages/productGroup'));
const UnitsManage = lazy(() => import('../Pages/UnitsManage'));
const GroupCustomer = lazy(() => import('../Pages/GroupCustomer'));
const UserPage = lazy(() => import('../Pages/userManagement'));
const CreateUser = lazy(() => import('../Pages/userManagement/createUser'));
const PaymentMethod = lazy(() => import('../Pages/paymentsMethod'));
const UserRole = lazy(() => import('../Pages/userRole'));
const UserRoleCreate = lazy(() => import('../Pages/userRole/create'));
const PrintBarcode = lazy(() => import('../Pages/product/printBarcode'));
const ProductPage = lazy(() => import('../Pages/product'));
const Consignment = lazy(() => import('../Pages/Consignment'));
const DeliveryNote = lazy(() => import('../Pages/DeliveryNote'));
const PrintTemplate = lazy(() => import('../Pages/PrintTemplate'));
const Setting = lazy(() => import('../Pages/Setting'));
const CreateGiveBackProductPage = lazy(() => import('../Pages/giveBackProduct/createOrder'));
const GiveBackProductPage = lazy(() => import('../Pages/giveBackProduct'));
const InventoryManagement = lazy(() => import('../Pages/inventoryManagement'));
const PageInventorySingle = lazy(() => import('../Pages/createInventory'));
const ReceiptManagement = lazy(() => import('../Pages/receiptManagement'));
const ReceiptCreate = lazy(() => import('../Pages/receiptManagement/components/createReceipt'));
const TypeOfReceipt = lazy(() => import('../Pages/typeOfReceipt'));
const ReportWarehouseManagement = lazy(() => import('../Pages/Report/Warehouse/index'));
const ImportWarehouse = lazy(() => import('../Pages/Report/Warehouse/Pages/Import'));
const ReportInventory = lazy(() => import('../Pages/Report/Warehouse/Pages/Inventory'));
const ImportExportWarehouse = lazy(() => import('../Pages/Report/Warehouse/Pages/ImportExport'));
const ReportBelowQuota = lazy(() => import('../Pages/Report/Warehouse/Pages/BelowQuota'));
const TransferWarehouse = lazy(() => import('../Pages/Report/Warehouse/Pages/Transfer'));
const ReportSaleManagement = lazy(() => import('../Pages/Report/Sales/index'));
const ReportSaleOverview = lazy(() => import('../Pages/Report/Sales/Pages/Overview'));
const ReportEmployee = lazy(() => import('../Pages/Report/Sales/Pages/Employee'));
const ReportOrderOrigin = lazy(() => import('../Pages/Report/Sales/Pages/OrderOrigin'));
const ReportLocation = lazy(() => import('../Pages/Report/Sales/Pages/Location'));
const ReportCustomer = lazy(() => import('../Pages/Report/Sales/Pages/Customer'));
const ReportProductRevenue = lazy(() => import('../Pages/Report/Sales/Pages/ProductRevenue'));
const OrderRevenue = lazy(() => import('../Pages/Report/Sales/Pages/OrderRevenue'));
const PaymentType = lazy(() => import('../Pages/paymentType/index'));
const PaymentManagement = lazy(() => import('../Pages/paymentManagement'));
const CreatePayment = lazy(() => import('../Pages/paymentManagement/component/createPayment/index'));
const CapitalAdjustment = lazy(() => import('../Pages/capitalPriceAdjustment'));
const CreateCapitalAdjustment = lazy(() => import('../Pages/capitalPriceAdjustment/component/createCapital/index'));
const CashBooks = lazy(() => import('Pages/CashBooks'));
const MiniGame = lazy(() => import('Pages/facebookManament/pages/minigame/component/body'));
const PosOrder = lazy(() => import('../Pages/Pos'));
const ReportShippingDifference = lazy(() => import('../Pages/Report/Sales/Pages/shippingDifference'));
const ReportShippingDifferenceEmployee = lazy(() => import('../Pages/Report/Sales/Pages/shippingDifferenceEmployee'));
const Feedback = lazy(() => import('../Pages/feedback'));
const PageBulkOrderDeduct = lazy(() => import('../Pages/bulkOrderDeduct'));
const PageBulkOrderDeductCreate = lazy(() => import('../Pages/bulkOrderDeduct/pages/create'));
const AddOnTools = lazy(() => import('../Pages/addOnTools'));
const LoginWarningManagement = lazy(() => import('Pages/loginWarning'));
const NotificationManagement = lazy(() => import('../Pages/NotificationManagement'));
const BombShipment = lazy(() => import('Pages/bombShipment'));
const PageBulkOrderDeductDetail = lazy(() => import('../Pages/bulkOrderDeduct/pages/bulkOrderDeductDetail'));
const PageFacebookAutoResponsesMessage = lazy(() => import('../Pages/facebookManament/pages/autoResponsesMessage'));
const PageFacebookOrderScriptPost = lazy(() => import('../Pages/facebookManament/pages/orderScriptPost'));
const PageFacebookOrderScriptSingle = lazy(() => import('../Pages/facebookManament/pages/orderScriptPost/single'));
const PageFacebookMessageConfirmOrder = lazy(() => import('../Pages/facebookManament/pages/messageConfirm'));

// const envLive = (location.host === 'banhang.upos.vn') || (location.host === 'evoshop.vn')
const envLive = false

export const warehouseRoute = [
  { path: PATH.WAREHOUSE_PRODUCT, component: InventoryInformation, prefix: 'inventory', action: 'view' },
  { path: PATH.WAREHOUSE_MANAGEMENT, component: WarehouseManagement, prefix: 'warehouse', action: 'view' },
  { path: PATH.WAREHOUSE_TRANSFER, component: WareHouseTransfer, prefix: 'warehouse_transfer', action: 'view' },
  { path: PATH.WAREHOUSE_TRANSFER_CREATE, component: CreateWarehouseTransfer, prefix: 'warehouse_transfer', action: 'add' },

  { path: PATH.PURCHASE, component: PurchasesManagement, prefix: 'purchase', action: 'view' },
  { path: PATH.PURCHASE_CREATE, component: CreatePurchases, prefix: 'purchase', action: 'add' },
  { path: PATH.QUICKLY_PURCHASE_CREATE, component: CreatePurchases, prefix: 'purchase', action: 'add' },
  { path: PATH.PURCHASE_EDIT, component: EditPurchases, prefix: 'purchase', action: 'edit' },
  { path: PATH.PURCHASE_REFUND, component: RefundPurchases, prefix: 'purchase', action: 'refund' },


  // path inventory management
  {path:PATH.INVENTORY_MANAGEMENT,component: InventoryManagement, prefix: 'warehouse_check', action: 'view'},
  {path:PATH.INVENTORY_CREATE,component: PageInventorySingle, prefix: 'warehouse_check', action: 'add'},
  {path:PATH.INVENTORY_EDIT,component: PageInventorySingle, prefix: 'warehouse_check', action: 'edit'},
  { path: PATH.INVENTORY_CONTROL, component: InventoryManagement, prefix: 'warehouse_check', action: 'view' },
]

export const partnerRoute = [
  { path: PATH.CUSTOMER, component: Customer, prefix: 'customer', action: 'view' },
  { path: PATH.CREATE_CUSTOMER, component: CreateCustomer, prefix: 'customer', action: 'add' },
  { path: PATH.EDIT_CUSTOMER, component: EditCustomer, prefix: 'customer', action: 'edit' },

  { path: PATH.SUPLIERS, component: SupplierManagement, prefix: 'supplier', action: 'view' },
  { path: PATH.SHIPPING_PARTNER, component: ShippingPartner, prefix: 'shipping_partner', action: 'view' },
  {path: PATH.BOMB_SHIPMENT, component: BombShipment, prefix: 'customer_report', action: 'view'},
]

export const addOnToolRoute = [
  { path: PATH.TOOL_BULK_ORDER, component: PageBulkOrder, prefix: 'bulks_order', action: 'view' },
  // { path: PATH.TOOL_BULK_ORDER_CREATE, component: PageBulkOrderCreate, prefix: 'bulks_order', action: 'view' },
  { path: PATH.BULK_ORDER_CREATE, component: PageBulkOrderCreate, prefix: 'bulks_order', action: 'view' },
  { path: PATH.BULK_ORDER, component: PageBulkOrderDetail, prefix: 'bulks_order', action: 'view' },

  // { path: PATH.TOOL_ADDRESS_SEPARATION, component: AddressSeparationTool },
  // { path: PATH.TOOL_ADDRESS_SEPARATION_FILE, component: AddressSeparationSingleFile },

  { path: PATH.TOOL_SHIPPING_TRACKING, component: ShippingTracking, prefix: 'shipping_tracking', action: 'view' },
  {path: PATH.FEEDBACK, component: Feedback, prefix: 'publish'},
  {path: PATH.LOGIN_WARNING, component: LoginWarningManagement, prefix: 'publish'},

    //inventory
  {path: PATH.TOOL_BULK_ORDER_DEDUCT,component: PageBulkOrderDeduct, prefix: 'bulks_order', action: 'view'},
  // {path: PATH.TOOL_BULK_ORDER_DEDUCT_CREATE,component: PageBulkOrderDeductCreate, prefix: 'bulks_order', action: 'view'},
  {path: PATH.BULK_ORDER_DEDUCT_CREATE,component: PageBulkOrderDeductCreate, prefix: 'bulks_order', action: 'view'},
  {path: PATH.BULK_ORDER_DEDUCT,component: PageBulkOrderDeductDetail, prefix: 'bulks_order', action: 'view'},

  {path:PATH.ADD_ON_TOOLS,component: AddOnTools, prefix: 'publish'}
]

export const deliveryRoute = [
  { path: PATH.DELIVERY_MANAGEMENT, component: DeliveryManagement, prefix: 'delivery', action: 'view' },
  { path: PATH.PART_SIGN, component: PartSign, prefix: 'partsign', action: 'view' },
  { path: PATH.DELIVERY_OVERVIEW, component: DeliveryOverview, prefix: 'delivery_overview', action: 'view' },
  { path: PATH.DELIVERY_COD_MANAGEMENT, component: CodManagement, prefix: 'cod_management', action: 'view' },
  { path: PATH.SHIPPING_TRACKING, component: ShippingTrackingManagement, prefix: 'abnormal_order', action: 'view' },
  { path: PATH.DELIVERY_OVERVIEW_DASHBOARD, component: DeliveryOverview, prefix: 'delivery_overview', action: 'view' },
  { path: PATH.DELIVERY_COD_CHECK, component: ComingSoon, prefix: 'publish' },
  // {path: PATH.FOR_CONTROL_COD, component: ForControlCOD, prefix: 'cod_management', action: 'view'},
]

export const cashBookRoute = [
  { path: PATH.CASHBOOK_RECEIPTS, component: ComingSoon, prefix: 'publish' },
  { path: PATH.CASHBOOK_PAYMENTS, component: ComingSoon, prefix: 'publish' },
  { path: PATH.COST_PRICE, component: ComingSoon, prefix: 'publish' },
]

export const productRoute = [
  { path: PATH.CREATE_PRODUCT, component: CreateProduct, prefix: 'product', action: 'add' },
  { path: PATH.EDIT_PRODUCT, component: CreateProduct, prefix: 'product', action: 'edit' },
  { path: PATH.PRINT_BARCODE_PRODUCT, component: PrintBarcode, prefix: 'product', action: 'view' },
  { path: PATH.PRODUCT_MANAGEMENT, component: ProductPage, prefix: 'product', action: 'view' },
]

export const orderRoute = [
  { path: PATH.ORDER, component: RefactorOrder, prefix: 'order', action: 'view' },
  { path: PATH.ORDER_ORIGIN, component: OrderOrigin, prefix: 'general_setting', action: 'order_origin' },
  { path: PATH.ORDER_CREATE, component: PageOrderSingle, prefix: 'order', action: 'add' },
  { path: PATH.EDIT_ORDER, component: PageEditOrderSingle, prefix: 'order', action: 'edit' },
  { path: PATH.COPY_ORDER, component: PageEditOrderSingle, prefix: 'order', action: 'add' },

  { path: PATH.GIVE_BACK_PRODUCT, component: envLive ? ComingSoon: GiveBackProductPage, prefix: 'order_refund', action: 'view' },
  { path: PATH.CREATE_GIVE_BACK_PRODUCT, component: envLive ? ComingSoon:  CreateGiveBackProductPage, prefix: 'order_refund', action: 'add' },
]

export const configAndSettingRoute = [
  { path: PATH.SETTING, component: Setting, prefix: 'publish' },
  { path: PATH.PRODUCT_GROUP, component: ProductGroup, prefix: 'general_setting', action: 'product_category' },
  { path: PATH.UNITS_MANAGE, component: UnitsManage, prefix: 'general_setting', action: 'units' },
  { path: PATH.GROUP_CUSTOMER, component: GroupCustomer, prefix: 'general_setting', action: 'customer_group' },
  { path: PATH.CONSIGNMENT, component: Consignment, prefix: 'general_setting', action: 'consignment' },
  { path: PATH.DELIVERY_NOTE, component: DeliveryNote, prefix: 'other_setting', action: 'note_template' },
  { path: PATH.PRINT_TEMPLATE_SETTING, component: PrintTemplate, prefix: 'other_setting', action: 'print_template' },

  { path: PATH.USER, component: UserPage, prefix: 'employee', action: 'view' },
  { path: PATH.CREATE_USER, component: CreateUser, prefix: 'employee', action: 'add' },

  { path: PATH.PAYMENT_METHOD, component: PaymentMethod, prefix: 'general_setting', action: 'payment_method' },

  { path: PATH.USER_ROLE, component: UserRole, prefix: 'permission', action: 'view' },
  { path: PATH.USER_ROLE_CREATE, component: UserRoleCreate, prefix: 'permission', action: 'add' },
  { path: PATH.USER_ROLE_EDIT, component: UserRoleCreate, prefix: 'permission', action: 'edit' },

  { path: PATH.TYPE_RECEIPT, component: TypeOfReceipt, prefix: 'general_setting', action: 'receipts_type' },
  { path: PATH.SETTING_TYPE_PAYMENT, component: PaymentType, prefix: 'general_setting', action: 'payments_type' }

]

export const facebookRoute = [
  {
    path: PATH.FACEBOOK_CONNECT,
    component: PageFacebookFanpage,
    prefix: 'facebook',
    action: 'fanpage'
  },
  {
    path: PATH.FACEBOOK_RESPONSE_CONTENT_SCRIPT,
    component: PageFacebookResponseContentScript,
    prefix: 'facebook',
    action: 'setting'
  },
  {
    path: PATH.FACEBOOK_HIDE_AUTO_COMMENT,
    component: PageFacebookHideAutoComment,
    prefix: 'facebook',
    action: 'setting'
  },
  {
    path: PATH.FACEBOOK_AUTO_RESPONSES,
    component: PageFacebookAutoResponses,
    prefix: 'facebook',
    action: 'setting'
  },
  {
    path: PATH.FACEBOOK_AUTO_RESPONSES_CREATE,
    component: CreateAutoResponses,
    prefix: 'facebook',
    action: 'setting'
  },
  {
    path: PATH.FACEBOOK_AUTO_MESSAGES,
    component: PageFacebookAutoResponsesMessage,
    prefix: 'facebook',
    action: 'setting',
  },
  {
    path: PATH.FACEBOOK_AUTO_RESPONSES_EDIT,
    component: CreateAutoResponses,
    prefix: 'facebook',
    action: 'setting'
  },
  {
    path: PATH.FACEBOOK_AUTO_MESSAGES_RESPONSES_CREATE,
    component: CreateAutoMessage,
    prefix: 'facebook',
    action: 'setting',
  },
  {
    path: PATH.FACEBOOK_AUTO_MESSAGES_RESPONSES_EDIT,
    component: CreateAutoMessage,
    prefix: 'facebook',
    action: 'setting',
  },
  {
    path: PATH.FACEBOOK_CONVERSATION_TICKERS,
    component: PageFacebookConversationStickers,
    prefix: 'facebook',
    action: 'setting'
  },
  {
    path: PATH.FACEBOOK_LIVESTREAM_SCRIPT,
    component: PageFacebookLivestreamScript,
    prefix: 'facebook',
    action: 'setting'
  },
  {
    path: PATH.FACEBOOK_LIVESTREAM_SCRIPT_SINGLE,
    component: PageFacebookLivestreamScriptSingle,
    prefix: 'facebook',
    action: 'setting'
  },
  {
    path: PATH.FACEBOOK_ORDER_SCRIPT_POST,
    component: PageFacebookOrderScriptPost,
    prefix: 'facebook',
    action: 'setting',
  },
  {
    path: PATH.FACEBOOK_ORDER_SCRIPT_POST_SINGLE,
    component: PageFacebookOrderScriptSingle,
    prefix: 'facebook',
    action: 'setting',
  },
  {
    path: PATH.FACEBOOK_PRINT_SETTING,
    component: PrintConfig,
    prefix: 'facebook',
    action: 'setting'
  },
  {
    path: PATH.FACEBOOK_MESSAGE_CONFIRM_ORDER,
    component: PageFacebookMessageConfirmOrder,
    prefix: 'facebook',
    action: 'setting',
  },
  {
    path: '/facebook/conversation',
    component: FacebookConversation,
    prefix: 'facebook',
    action: 'conversation'
  },
  {
    path: '/facebook/orders',
    component: FacebookOrders,
    prefix: 'facebook',
    action: 'orders'
  },

  {
    path: '/facebook/livestream',
    component: FacebookLivestream,
    prefix: 'facebook',
    action: 'livestream'
  },

  {
    path: '/facebook/:pageId/livestream/:liveStreamId',
    component: FacebookLiveStreamDetail,
    prefix: 'facebook',
    action: 'livestream'
  },

  {
    path: `${PATH.MINI_GAME}/:idMiniGame`,
    component: MiniGame,
    prefix: 'facebook',
    action: 'livestream'
  },
]

export const accountantRoute = [
  { path: PATH.ACCOUNTANT_RECEIPTS, component: ReceiptManagement, prefix: 'receipts', action: 'view' },
  { path: PATH.ACCOUNTANT_RECEIPT_CREATE, component: ReceiptCreate, prefix: 'receipts', action: 'add' },
  { path: PATH.ACCOUNTANT_PAYMENT, component: PaymentManagement, prefix: 'pays', action: 'view' },
  { path: PATH.ACCOUNTANT_PAYMENT_CREATE, component: CreatePayment, prefix: 'pays', action: 'add' },
  { path: PATH.PRICE_ADJUSTMENT, component: CapitalAdjustment, prefix: 'cost_price', action: 'view' },
  { path: PATH.PRICE_ADJUSTMENT_CREATE, component: CreateCapitalAdjustment, prefix: 'cost_price', action: 'add' },
  { path: PATH.PRICE_ADJUSTMENT_EDIT, component: CreateCapitalAdjustment, prefix: 'cost_price', action: 'edit' },
  { path: PATH.CASHBOOKS, component: CashBooks, prefix: 'cashbook', action: 'view' },
]


export const reportRoute = [

  //report warehouse

  { path: PATH.REPORT_WAREHOUSE, component: ReportWarehouseManagement, prefix: 'publish' },
  { path: PATH.REPORT_WAREHOUSE_IMPORT, component: ImportWarehouse, prefix: 'warehouse_report', action: 'purechase' },
  { path: PATH.REPORT_WAREHOUSE_INVENTORY, component: ReportInventory, prefix: 'warehouse_report', action: 'inventory' },
  { path: PATH.REPORT_WAREHOUSE_NOTE, component: ComingSoon, prefix: 'publish' },
  { path: PATH.REPORT_WAREHOUSE_IMPORT_EXPORT, component: ImportExportWarehouse, prefix: 'warehouse_report', action: 'import_export' },
  { path: PATH.REPORT_WAREHOUSE_QUANTITY_LOW_RATE, component: ReportBelowQuota, prefix: 'warehouse_report', action: 'low_rate' },
  { path: PATH.REPORT_WAREHOUSE_TRANSFER, component: TransferWarehouse, prefix: 'warehouse_report', action: 'transfer' },

  //report sales

  { path: PATH.REPORT_SALES, component: ReportSaleManagement, prefix: 'publish' },
  { path: PATH.REPORT_SALES_OVERVIEW, component: ReportSaleOverview, prefix: 'sale_report', action: 'overview' },
  { path: PATH.REPORT_SALES_EMPLOYEE, component: ReportEmployee, prefix: 'sale_report', action: 'employee' },
  { path: PATH.REPORT_SALES_ORDER_ORIGIN, component: ReportOrderOrigin, prefix: 'sale_report', action: 'origin' },
  { path: PATH.REPORT_SALES_LOCATION, component: ReportLocation, prefix: 'sale_report', action: 'area' },
  { path: PATH.REPORT_SALES_CUSTOMER, component: ReportCustomer, prefix: 'sale_report', action: 'customer' },
  { path: PATH.REPORT_SALES_PRODUCT_REVENUE, component: ReportProductRevenue, prefix: 'sale_report', action: 'product_revenue' },
  { path: PATH.REPORT_SALES_ORDER_REVENUE, component: OrderRevenue, prefix: 'sale_report', action: 'order_revenue' },
  { path: PATH.REPORT_SALES_SHIPPING_DIFFERENCE, component: ReportShippingDifference, prefix: 'sale_report', action: 'shipping_fee' },
  { path: PATH.REPORT_SALES_SHIPPING_DIFFERENCE_EMPLOYEE, component: ReportShippingDifferenceEmployee, prefix: 'sale_report', action: 'shipping_fee' },
  { path: PATH.REPORT_SALES_POS_OVERVIEW, component: ComingSoon, prefix: 'publish' },
]

export const posRoute = [
  { path: PATH.POS, component: PosOrder, prefix: 'pos', action: 'view' },
]
export const notificationRoute = [
  {path:PATH.NOTIFICATION_MANAGEMENT,component: NotificationManagement, prefix: 'publish'}
]