import React, {createContext, useEffect, useReducer} from 'react'
import config from 'config'
import {Grid} from '@material-ui/core'
import {useConfigContext} from '../../Component/NavBar/navBar'
import {InitialState} from './store/dashboardInitState'
import DashboarReducer from './store/dasboardReducer'
import AppRevenueOverTime from './component/revenue/AppRevenueOverTime'
import AppOrderRateByShipping from './component/OrderRateByShipping/AppOrderRateByShipping'
import TopProducts from './component/topproduct'
import BannerTopRight from './component/bannerTopRight/index'
import PackageRenewal from './component/packageRenewal/index'
import HeaderDashboard from './component/headerDashboard/index'
import './ControlPanel.scss'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import useGlobalContext from "../../containerContext/storeContext";
import ModalNotification from "../../Component/modalNotification";
import ModalGuideScreen from './component/guideScreen/modalGuideScreen'
import {sendRequestAuth} from 'api/api'
import {useControlPanel} from "./hooks/useControlPanel";
export const DashboardContext = createContext(InitialState)

const DashboardStore = ({children}) => {
  const [state, dispatch] = useReducer(DashboarReducer, InitialState)
  const fetchApiBanner = async () =>{
    const isShow = JSON.parse(window.localStorage.getItem('isShowPopupEvo'))
    const response = await Promise.all([
      sendRequestAuth('get',`${config.API}/banner-popup/list?type=banner&position`),
      sendRequestAuth('get',`${config.API}/banner-popup/list?type=popup&position`),
    ])
    if(!!response[0].data?.success && !!response[1].data?.success){
      let data = {}
      data={
        status: (response[1]?.data?.data[0]?.id !== isShow?.id && response[1].data?.data?.length > 0) ? true : false,
        id: response[1]?.data?.data[0]?.id !== isShow?.id ? response[1]?.data?.data[0]?.id : isShow?.id}

      window.localStorage.setItem('isShowPopupEvo', JSON.stringify(data))

      dispatch({type:'LIST_BANNER_POPUP',payload:{
          banner: response[0]?.data?.data?.splice(0,5),
          popup: response[1]?.data?.data
        }})
    }

  }
  useEffect(()=>{
    fetchApiBanner()
  },[])
  return (
    <DashboardContext.Provider value={{state, dispatch}}>
      {children}
    </DashboardContext.Provider>
  )
}

export default function ControlPanel() {

  const { t } = useTranslation()
  const [state, dispatch] = useReducer(DashboarReducer, InitialState)
  const {functions,data} = useControlPanel(state, dispatch)
  const [stateGlobal] = useGlobalContext()
  const changeFilter = day => {
    const date = new Date()
    const date_start = moment(
      new Date(date.getTime() - day * 24 * 60 * 60 * 1000),
    ).format('YYYY-MM-DD')
    dispatch({type: 'CHANGE_START_TIME', payload: date_start})
    dispatch({type: 'CHANGE_DAY', payload: day})
  }
  useEffect(()=>{
    if(location.pathname === '/admin' || location.pathname === '/' || location.pathname === '/login'){
      functions.origin()
    }
    document.title = "Tổng quan"
  },[])
  return (
    <DashboardStore>
      <Grid container className="control-panel-wrapper">
        <HeaderDashboard changeFilter={changeFilter} data={state} />
        <Grid
          className="control-panel-col-1"
          container
          xs={12}
          sm={12}
          md={9}
          lg={9}
        >
          <AppRevenueOverTime
            title={t('revenue_time')}
            startTime={state.date_start}
            endTime={state.date_end}
          />
          <Grid
            className="upos-trans-wrapper"
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Grid
              className="upos-trans-check-wrapper"
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={8}
            >
              <AppOrderRateByShipping
                title={t('order_rate_by_shipping_status')}
                startTime={state.date_start}
                endTime={state.date_end}
              />
            </Grid>

            <Grid
              className="upos-trans-check-wrapper"
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={4}
            >
              <div className="body-trans-check-wrapper">
                <TopProducts
                  title={t("top_bestselling_products")}
                  startTime={state.date_start}
                  endTime={state.date_end}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="control-panel-col-2" container xs={12} sm={12} md={3}>
          <Grid className="upos-service-wrapper" item xs={12} sm={12} md={12}>
            <div className="body-service-wrapper">
              <BannerTopRight />
            </div>
            <div className="body-guide-wrapper">
              <PackageRenewal
                startTime={state.date_start}
                endTime={state.date_end}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      {+stateGlobal.user.is_first_login !== 0 ? <ModalGuideScreen /> : ''}
      <div className="fake-div-80"></div>
      {data.openModal && <ModalNotification openModal={data.openModal} setOpenModal={functions.closeModal} item={data.detailNotice}/>}
    </DashboardStore>
  )
}
