import cls from 'clsx'
import {Text} from 'common/text'
import {PATH} from 'const/path'
import useGlobalContext from 'containerContext/storeContext'
import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import ReactImageFallback from 'react-image-fallback'
import {Navigate, useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import {getData, sendRequestAuth} from '../../api/api'
import {getUrlLogOut} from '../../api/url'
import {IconInfo} from '../UserInformation/icon'
import css from '../UserInformation/index.module.scss'
import {CustomToolTip} from '../tooltip/CustomTooltip'
import {general, generalStore} from './account'
import StoreInfomation from './component/modal'
import CustomerManagementModal from './component/customerManagement'
import {StoreAccountContext} from './reducer/context'
import {useAccountAction} from './reducer/reducer'
import {ICON_ACCOUNT} from './interface/icon'
import config from '../../config'
import useAlert from '../../hook/useAlert'
import useCustomerAccount from './hook/useCustomerAccount'
import {Tooltip} from "../../common/tooltip";

export const AccountDropdown = ({
                                   data,
                                   setCloseAnchor,
                                   fetchShopInfo,
                                   setUserInfoTab,
                                   setUserInfoOpen,
                                   setUserInfoAnimationClose,
                                   ...props
                                }) => {
   const [state, dispatch] = useGlobalContext()
   const {shopInfo, user, userInfoPopup} = state
   const {t} = useTranslation()
   const {pageDispatch} = useContext(StoreAccountContext)
   const nav = useNavigate()
   const {showAlert} = useAlert()
   const {
      handleLogoutCurrentAccount,
      addNewAccount,
      switchAccount,
      deviceTokenList,
   } = useCustomerAccount()

   // Customer Management Modal start
   const [openCustomerManagement, setOpenCustomerManagement] = useState(false)
   const [bolUpdateCustomerManagement, setBolUpdateCustomerManagement] =
      useState(false)
   const [aniModalCloseCustomerManagement, setAniModalCloseCustomerManagement] =
      useState(false)

   const closeBolupdateCustomerManagement = () => {
      setBolUpdateCustomerManagement(false)
   }
   const closeAnimateCustomerManagement = () => {
      setAniModalCloseCustomerManagement(true)
   }
   const handleCloseModalCustomerManagement = () => {
      setOpenCustomerManagement(false)
      setAniModalCloseCustomerManagement(false)
      // pageDispatch({type: useAccountAction.UPDATE_STORE_CONFIG, payload: true})
   }
   const isUpdateCustomerManagement = bol =>
      bol
         ? setBolUpdateCustomerManagement(true)
         : setBolUpdateCustomerManagement(false)
   const changeAnimateCustomerManagement = () => {
      setAniModalCloseCustomerManagement(false)
   }
   // Customer Management modal end

   // StoreInfomation modal start
   const [open, setOpen] = useState(false)
   const [tab, setTab] = useState('store')
   const [bolUpdate, setBolUpdate] = useState(false)
   const [aniModalClose, setAniModalClose] = useState(false)
   const closeBolupdate = () => {
      setBolUpdate(false)
   }
   const closeAnimate = () => {
      setAniModalClose(true)
   }
   const handleCloseModal = () => {
      setOpen(false)
      setAniModalClose(false)
      pageDispatch({type: useAccountAction.UPDATE_STORE_CONFIG, payload: true})
   }
   const isUpdate = bol => (bol ? setBolUpdate(true) : setBolUpdate(false))
   const changeAnimate = () => {
      setAniModalClose(false)
   }
   const handleOpen = id => {
      switch (id) {
         case 1:
            setOpen(true)
            setAniModalClose(false)
            setTab('store')
            break
         case 2:
            setOpen(true)
            setAniModalClose(false)
            setTab('store config')
            break
         default:
            nav(id)
            break
      }
   }
   // StoreInfomation modal end

   const handleClick = value => {
      setCloseAnchor()
      switch (value) {
         case 'user_information': {
            setUserInfoOpen(true)
            setUserInfoAnimationClose(false)
            setUserInfoTab('user')
            break
         }
         case 'change_password': {
            setUserInfoOpen(true)
            setUserInfoAnimationClose(false)
            setUserInfoTab('password')
            break
         }
         case 'history_login': {
            nav('/login-warning')
            break
         }
         case 'log_out': {
            handleLogoutCurrentAccount()
            break
         }
         default:
            break
      }
   }
   useEffect(() => {
      if (userInfoPopup) {
         handleClick('change_password')
         dispatch({type: 'SET_USER_INFO_POPUP', payload: false})
      }
   }, [userInfoPopup])

   return (
      <Styled {...props}>
         <div className="account-dropdown__header">
            <div className="account-dropdown__header-bg">
            </div>
            <div
               className={`${cls(css.group_info)} account-dropdown__header-info`}
               onClick={() => handleClick('user_information')}
            >
               <ReactImageFallback
                  className={cls(css.avatar)}
                  src={'/img/iconMenu/deAvatar.png'}
                  alt={'avatar'}
                  fallbackImage="/img/iconMenu/defaultAvatar.png"
               />
               <div className={cls(css.inner)}>
                  {user.fullname?.length < 30 ? (
                     <Text as={'p'} fontWeight={600} fontSize={15}>{user.fullname}</Text>
                  ) : (
                     <CustomToolTip
                        title={<p className={cls(css.name)}>{user.fullname}</p>}
                        arrow
                     >
                        <Text as={'p'} className={cls(css.name)} fontWeight={600} fontSize={15}>{user.fullname}</Text>
                     </CustomToolTip>
                  )}
                  {user.email?.length < 30 ? (
                     <Text as={'p'} className={cls(css.email)} fontSize={12}>{user.email}</Text>
                  ) : (
                     <CustomToolTip
                        title={<p className={cls(css.email)}>{user.email}</p>}
                        arrow
                     >
                        <p className={cls(css.email)}>{user.email}</p>
                     </CustomToolTip>
                  )}
               </div>
               <div>
                  <svg
                     width="20"
                     height="20"
                     viewBox="0 0 20 20"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M13.0942 10.0005L8.0859 4.99212L6.90674 6.16962L10.7401 10.0005L6.90674 13.8305L8.08507 15.0088L13.0942 10.0005Z"
                        fill="#56697C"
                     />
                  </svg>
               </div>
            </div>
            {/* User start */}

            {/* User end */}
         </div>
         <div className="account-dropdown__menu">
            <ul className="account-all-dropdown">
               <div
                  className="account-all-dropdown__title"
                  style={{marginBottom: 12}}
               >
                  <Text fontSize={15} fontWeight={500}>
                     {t('formLogin_all_account')} ({deviceTokenList?.length || 0})
                  </Text>
                  <div
                     className="account-all-dropdown__icon-setting"
                     onClick={() => {
                        setAniModalClose(false)
                        setOpenCustomerManagement(true)
                     }}
                  >
                     {ICON_ACCOUNT.setting}
                  </div>
               </div>
               <div className="account-all-dropdown__account">
                  {deviceTokenList?.map((item, key) => {
                     if (key < 5)
                        return (
                           <li
                              key={key}
                              onClick={() =>
                                 item?.user_id !== user?.user_id && switchAccount(item)
                              }
                           >
                              <div style={{display: 'flex', width: '100%'}}>
                                 <img
                                    src={item?.shop?.store_logo || '/img/logo-store.png'}
                                    alt="avatar"
                                    width={36}
                                    height={36}
                                    style={{
                                       overflow: 'hidden',
                                       borderRadius: '50%',
                                       width: 36,
                                       height: 36,
                                    }}
                                 />
                                 <Text
                                    as={'p'}
                                    style={{
                                       display: 'grid',
                                       marginLeft: '12px',
                                       width: 'calc(100% - 72px)',
                                    }}
                                 >
                                    <Tooltip className={'account__tooltipV2'} title={item?.shop?.store_name}
                                             baseOn={'height'}>
                                       {item?.shop?.store_name}
                                    </Tooltip>
                                    {key === 0 ? (
                                       <>
                                          <Text color={'#00AB56'} fontSize={12}>
                                             {t('formLogin_Accessing')}
                                          </Text>
                                          <div style={{position: 'absolute', right: '8px', top: '20px'}}>
                                             <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                             >
                                                <path
                                                   fill-rule="evenodd"
                                                   clip-rule="evenodd"
                                                   d="M7.50013 13.636L4.02513 10.161L2.8418 11.336L7.50013 15.9943L17.5001 5.99434L16.3251 4.81934L7.50013 13.636Z"
                                                   fill="#040711"
                                                />
                                             </svg>
                                          </div>
                                       </>
                                    ) : (
                                       <Text color={'#606060'} fontSize={12}>
                                          {item?.shop?.vip_code_jnt}
                                       </Text>
                                    )}
                                 </Text>
                              </div>
                           </li>
                        )
                  })}
                  <li onClick={addNewAccount}>
                     <div style={{display: 'flex', alignItems: 'center'}}>
                        {ICON_ACCOUNT.plus}
                        <Text
                           style={{
                              display: 'flex',
                              marginLeft: '12px',
                              fontSize: 15,
                              fontWeight: 500,
                           }}
                        >
                           {t('formLogin_Add_Account')}
                        </Text>
                     </div>
                  </li>
               </div>
            </ul>
            <ul>
               {generalStore.map(item => {
                  return (
                     <li
                        key={item.class}
                        className={cls(css[item.class])}
                        data-active={location.pathname === item.url}
                        onClick={() => handleOpen(item.url)}
                        style={{display: 'flex', justifyContent: 'space-between'}}
                     >
                        {t(item.label)}
                        {item.icon}
                     </li>
                  )
               })}
               {general.map((item, key) => {
                  return (
                     <li
                        key={item.class}
                        className={cls(css[item.class])}
                        onClick={() => handleClick(item.value)}
                        style={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           color: key + 1 === general.length ? '#FF424E' : '',
                        }}
                     >
                        {t(item.label)}
                     </li>
                  )
               })}
            </ul>
         </div>
         {open && (
            <StoreInfomation
               open={open}
               handleCloseModal={handleCloseModal}
               bolUpdate={bolUpdate}
               closeBol={closeBolupdate}
               aniModalClose={aniModalClose}
               closeAnimate={closeAnimate}
               isUpdate={isUpdate}
               info={data}
               fetchShopInfo={fetchShopInfo}
               setCloseAnchor={setCloseAnchor}
               changeAnimate={changeAnimate}
               tab={tab}
            />
         )}
         {openCustomerManagement && (
            <CustomerManagementModal
               open={openCustomerManagement}
               handleCloseModal={handleCloseModalCustomerManagement}
               bolUpdate={bolUpdateCustomerManagement}
               closeBol={closeBolupdateCustomerManagement}
               aniModalClose={aniModalCloseCustomerManagement}
               closeAnimate={closeAnimateCustomerManagement}
               isUpdate={isUpdateCustomerManagement}
               info={data}
               fetchShopInfo={fetchShopInfo}
               setCloseAnchor={setCloseAnchor}
               changeAnimate={changeAnimateCustomerManagement}
            />
         )}
      </Styled>
   )
}

const Styled = styled.div`
  .account-dropdown {
    &__header {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      
      position: relative;
    }
    &__header-bg {
      width: 100%;
      height: 90px;
      background: linear-gradient(31deg, #FF424F 37.07%, #696CFF 57.48%);
      filter: blur(0px);
      opacity: 0.075;
    }
    &__header-info {
      position: absolute;
      width: 90%;
      top: 20px;
    }

    &__avatar {
      width: 50px;
      height: 50px;

      overflow: hidden;

      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center;
      }
    }

    &__info {
      text-align: center;
      margin-top: 12px;
    }

    &__menu {
      border-bottom: 1px solid #ebeef5;

      &:last-child {
        border: none;
        padding-bottom: 0;
      }
    }

    &__menu-item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;

      color: #151624;
      transition: all 0.25s;

      cursor: pointer;

      &:last-child {
        margin: 0;
      }

      &:hover {
        background: #f3f6fc;
        color: #e5101d;
      }
    }
  }

  .account-all-dropdown {
    &__title {
      display: flex;
      justify-content: space-between;
      margin: 16px 24px;
    }

    &__icon-setting {
      cursor: pointer;

      &:hover {
        svg {
          color: #e5101d !important;

          path {
            fill: #e5101d;
            &:last-child {
              fill: none;
              stroke: #e5101d;
            }
          }
        }
      }
    }

    &__account {
      position: relative;
      margin: 0px 12px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 8px;
      background: #f5f6f8;
      overflow:hidden;
      li {
        cursor: pointer;
        background: #f5f6f8;
        margin: 0;
        padding: 8px;
        border-radius: 0px !important;
        &:hover {
          background: #E0E0E0;
        }
      }
    }
  }
     
   .account__tooltipV2 {
     display: -webkit-box;
     height: 100%;
     -webkit-line-clamp: 1;
     -webkit-box-orient: vertical;
     overflow: hidden;
     text-overflow: ellipsis;
   }
`
