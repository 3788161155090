import { Popper } from './component/popoverNotice'
import React, { createContext, useEffect, useReducer, useState } from 'react'
import { HeaderNotification } from './component/header'
import { TableNotifications } from './component/table'
import './style.scss'
import { IconBell } from './interfaces/_icon'
import { THEME_COLORS } from 'common/theme/_colors'
import { Text } from 'common/text'
import { Link } from 'react-router-dom'
import useGlobalContext from '../../containerContext/storeContext'
import { PATH } from '../../const/path'
import { noticeCompReducer } from './provider/_reducer'
import { noticeCompState } from './provider/_initialState'
import ModalNotification from '../modalNotification'
import { noticeCompAction } from './provider/_action'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '../../common/tooltip'

export const NotificationContext = createContext()

export default function Notification () {
  const { t } = useTranslation()

  const [state, dispatch] = useReducer(noticeCompReducer, noticeCompState)
  const [GlobalState, GlobalDispatch] = useGlobalContext()
  const { notice } = GlobalState.totalNotice
  const closeModal = (boo) => {
    dispatch({ type: noticeCompAction.OPEN_MODAL_NOTIFICATION, payload: boo })

  }

  useEffect(() => {
    dispatch({
      type: noticeCompAction.GET_LIST_NOTICE, payload: {
        all: {
          list: notice?.allList?.list,
          perPage: 20,
          total: notice?.allList.total,
        }
      }
    })
    dispatch({
      type: noticeCompAction.GET_TOTAL_NOTICE, payload: {
        all: GlobalState.totalNotice.all,
      }
    })
  }, [notice?.allList])
  return (
    <>
      <Popper
        renderPopper={({ onClose }) => (
          <NotificationContext.Provider value={{ pageState: state, pageDispatch: dispatch }}>
            <div className="notification">
              <HeaderNotification/>
              <TableNotifications onClose={onClose}/>
              {state.notice?.all?.total > 0 && (
                <div style={{ textAlign: 'center', paddingTop: '8px' }} onClick={onClose}>
                  <Link to={`${PATH.NOTIFICATION_MANAGEMENT}` || '#'}>

                    <Text color={THEME_COLORS.blue_500} fontWeight={500}>
                      {t('see_all_notification')}
                    </Text>

                  </Link>
                </div>
              )}
            </div>
          </NotificationContext.Provider>
        )}
        renderToggle={_ => (<Tooltip title={t('notification')}><IconBell/></Tooltip>)}
        placement="bottom-end"
        popperProps={{
          className: 'notification-bell',
        }}
        noticeValue={+GlobalState.totalNotice.all > 0 && GlobalState.totalNotice.all}
        noticeClass={'notification-bell-total'}
        popoverMainClass={'notice-component-popover'}
      />

      {state?.openModal &&
      <ModalNotification openModal={state?.openModal} setOpenModal={closeModal} item={state.detailNotice}/>}
    </>

  )
}
