import {postData} from "../../../api/api";
import {updloadLogoShopInfo} from "../../../api/url";
import {ERROR_STORE} from "../interface/_script";
import {useTranslation} from "react-i18next";
import {updateInfoShop} from "../component/modal/Tab/storeInfomation/update";

export const useUpdateLogo = (info, fetchShopInfo, handleCheck) => {
   const {t} = useTranslation()
   const handleUpdate = async (val) => {
      let image = val.target.files[0]
      const formData = new FormData()
      formData.append('image', image)
      formData.get('image')
      try {
         const res = await postData(updloadLogoShopInfo(info?.shop_id), formData)
         if (res.data.success) {
            handleCheck(false, '')
            if (!!res.data?.url) updateInfoShop({
               store_logo: res.data?.url || '',
            })
            fetchShopInfo()
         } else handleCheck(true, t(ERROR_STORE.IMAGE))
      } catch (e) {
         console.log(e)
      }

   }
   return {
      handleUpdate,
   }
}