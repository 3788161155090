import * as React from 'react'
import Popover from '@mui/material/Popover'
import { Button } from 'common/button'
import './index.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export default function BasicPopover ({ ...props }) {
  const { t } = useTranslation()
  const { disabled } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const navigate = useNavigate()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const clickActionItem = (item) => {
    item?.action()
    handleClose()
  }

  const handleClickItem = item => {
    if (typeof item.action === 'function') clickActionItem(item)
    else navigate(item?.url)

    handleClose()
  }

  return (
    <div>
      <Button
        onClick={handleClick}
        aria-describedby={id}
        variant="contained"
        disabled={disabled}
        className={'header-left__item-btn_right'}
      >
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M3.36612 7.33474C3.85427 6.88842 4.64573 6.88842 5.13388 7.33474L10.5 12.2409L15.8661 7.33474C16.3543 6.88842 17.1457 6.88842 17.6339 7.33474C18.122 7.78105 18.122 8.50467 17.6339 8.95098L11.3839 14.6653C10.8957 15.1116 10.1043 15.1116 9.61612 14.6653L3.36612 8.95098C2.87796 8.50467 2.87796 7.78105 3.36612 7.33474Z"
                fill="white"/>
        </svg>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          color: 'success.dark',
          display: 'inline',
          fontWeight: 'bold',
          fontSize: 14,
          top: 4
        }}
      >
        <StyledPopover {...props}>
          <ul className="common-popover__selected-action-menu">
            {props.item?.items.map((item, index) => (
              <li
                className="common-popover__selected-action-menu-item common-popover__selected-action-menu-item--custom"
                key={index}
              >
                <Button className="common-popover__selected-action-menu-item--button"
                        icon={<svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M16.6673 11.7719H11.1118V17.3275H8.88954V11.7719H3.33398V9.5497H8.88954V3.99414H11.1118V9.5497H16.6673V11.7719Z"
                            fill="#56697C"/>
                        </svg>
                        }
                        data-active={location.pathname === item.url}
                        disabled={disabled} onClick={() => handleClickItem(item)}>{t(item.name)}</Button>
                {/*<Link disabled={disabled} to={item.url}>{t(item.name)}</Link>*/}
              </li>
            ))}
          </ul>
        </StyledPopover>
      </Popover>
    </div>
  )
}
export const StyledPopover = styled.div`
  width: ${props => props.width || '182px'};
 .common-popover__selected-action-menu-item {
    &--custom {
      padding: 0 !important;
    }
    &--button {
      background: white;
      color: black;
      border: none;
      font-weight: 400;
      font-size: 14px;
      &:hover {
        color: #E5101D;
        svg path{
          fill: #E5101D;
        }
      }
      &[data-active=true]{
        color: #E5101D;
        svg path{
          fill: #E5101D;
        }
      }
    }
 }
`