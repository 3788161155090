import cls from 'clsx'
import { useEffect, useState } from 'react'
import { getData } from '../../api/api'
import {getShopInfo, getUrlLogOut} from '../../api/url'
import useGlobalContext from '../../containerContext/storeContext'
import { AccountDropdown } from './_dropdown'
import { useEditStoreConfig } from './hook/useEditStoreConfig'
import css from './index.module.scss'
import { StoreAccountProvider } from './reducer/index'
import { useAccountAction } from './reducer/reducer'
import {PATH} from "../../const/path";
import {useNavigate} from "react-router-dom";

const Index = ({setAnchorEl, setUserInfoTab, setUserInfoOpen, setUserInfoAnimationClose}) => {
  const [state, dispatch] = useGlobalContext()
  const {provider} = useEditStoreConfig()
  const [data, setData] = useState()
  const nav = useNavigate()
  const setCloseAnchor = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    fetchShopInfo()
  }, [])
  const fetchShopInfo = async () => {
    try {
      provider.dispatch({
        type: useAccountAction.SET_WARNING_PHONE_CREATE_ORDER,
        payload: +state?.shopInfo?.ct_order_warning !== 1,
      })
      const res = await getData(getShopInfo())
      if (res.data.success) {
        setData(res.data.data)
        provider.dispatch({
          type: useAccountAction.GET_DETAIL_STORE_CONFIG,
          payload: res.data.data,
        })
        let deviceToken = JSON.parse(window.localStorage.getItem('device_token'))
        if(!!!deviceToken){
          const url = getUrlLogOut()
          getData(url)
             .then(() => {
               dispatch({type: 'SET_LOGOUT'})
               dispatch({type: 'SET_USER', payload: {}})
               nav(PATH.LOGIN, {state: {addNew: false}})
             })
             .catch(() => {
               console.log('error logout')
             })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <StoreAccountProvider
      value={{pageState: provider.state, pageDispatch: provider.dispatch}}
    >
      <div className={cls(css.wrapper)}>
        <AccountDropdown
          fetchShopInfo={fetchShopInfo}
          data={data}
          setCloseAnchor={setCloseAnchor}
          setUserInfoTab={setUserInfoTab}
          setUserInfoOpen={setUserInfoOpen}
          setUserInfoAnimationClose={setUserInfoAnimationClose}
        />
      </div>
    </StoreAccountProvider>
  )
}

export default Index
