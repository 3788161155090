import React, { useContext, useEffect, useState } from 'react'
import { Modal, Box } from '@mui/material'
import { Button } from 'common/button'
import { Text } from 'common/text'
import { StyledGuideScreen } from './_styled'
import { useWindowSize } from 'Component/ListMenu/listMenu'
import useGlobalContext from 'containerContext/storeContext'
import config from 'config'
import { sendRequestAuth } from 'api/api'
import { useTranslation } from 'react-i18next'

export default function ModalGuideScreen () {
  const [width] = useWindowSize()
  const [state, dispatch] = useGlobalContext()
  const { t } = useTranslation()

  const updateFirstLogin = async () => {
    const response = await sendRequestAuth(
      'post',
      `${config.API}/admin/employee/update-first-login/${state.user.user_id}`,
      []
    )
    if (response?.data?.success) {
      dispatch({ type: 'SET_IS_FIRST_LOGIN', payload: 0 })
    }
  }
  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={'modal-guide__screen'}
    >
      <StyledGuideScreen>
        {+state.user.is_first_login === 1 ?
          <div>
            <Box className="guide-screen-confirm">
              <div className="guide-screen-confirm_frame">

                <img src="/img/guideScreen/logo.png"/>
                <div className="guide-screen-confirm_frame-welcome">
                  <Text color={'#fff'}
                        as={'p'}
                        fontSize={24} lineHeight={38}>
                    {t('welcome_to_evoshop')}
                  </Text>
                </div>
                <div className='guide-screen-confirm_button'>

                  <Button className='guide-screen-confirm_next_tutorial'
                          appearance={'secondary'}
                          onClick={() => updateFirstLogin()}
                  >{t('skip_instructions')}</Button>
                  <Button className='guide-screen-confirm_continue'
                          onClick={() => {
                            dispatch({ type: 'SET_IS_FIRST_LOGIN', payload: 2 })
                          }}
                  >{t('continue')}</Button>

                </div>
                <a href="https://help.evoshop.vn" target={'__blank'}>
                  <Text
                    as="p"
                    lineHeight={24}
                    className='guide-screen-confirm_text_tutorial'
                  >
                    {t('view_detailed_usage_instructions')}
                  </Text></a>
              </div>
            </Box>
            <div>
              <div
                className={`guide-screen-confirm_tutorial_step1 ${width > 1440 ? '' : 'guide-screen-confirm_tutorial_step1_minimize'}`}>
                <Text
                  as="p"
                  lineHeight={24}
                  className='guide-screen-confirm_tutorial_step1_text'
                  color={'#fff'}
                  fontSize={17}
                  style={{ fontStyle: 'italic' }}
                >
                  {t('useful_tools_1')} <Text fontSize={17} color={'#F9E429'}
                                              style={{ fontStyle: 'italic' }}>{t('useful_tools_2')}</Text> {t('useful_tools_3')}
                </Text>
                <Text
                  as="p"
                  lineHeight={24}
                  className='guide-screen-confirm_tutorial_step1_frame'
                >
                  <img src="/img/guideScreen/frame1.png"/>
                </Text>
              </div>
              <div
                className={`guide-screen-confirm_tutorial_step2 ${width > 1440 ? '' : 'guide-screen-confirm_tutorial_step2_minimize'}`}>
                <Text
                  as="p"
                  lineHeight={24}
                  className='guide-screen-confirm_tutorial_step2_frame'
                >
                  <img src="/img/guideScreen/frame2.png"/>
                </Text>
                <Text
                  as="p"
                  lineHeight={24}
                  className='guide-screen-confirm_tutorial_step2_text'
                  color={'#fff'}
                  fontSize={17}
                  style={{ fontStyle: 'italic' }}
                >
                  {t('navigation_menu_1')} <Text fontSize={17} color={'#F9E429'}
                                                 style={{ fontStyle: 'italic' }}>{t('navigation_menu_2')}</Text> {t('navigation_menu_3')}
                </Text>
              </div>
              <div className={`guide-screen-confirm_tutorials_frame`}>
                <div className='guide-screen-confirm_tutorials_frame_step4'>
                  <Text
                    as="p"
                    lineHeight={24}
                    className='guide-screen-confirm_tutorials_frame_step4_frame'
                  >
                    <img src="/img/guideScreen/frame4.png"/>
                  </Text>
                </div>
                {/*<div className='guide-screen-confirm_tutorials_frame_step5'>*/}
                {/*  <div className='guide-screen-confirm_tutorials_frame_step5_1'>*/}
                {/*    {state?.shopInfo?.store_name}*/}
                {/*  </div>*/}
                {/*  <Text*/}
                {/*    as="p"*/}
                {/*    lineHeight={24}*/}
                {/*    className='guide-screen-confirm_tutorials_frame_step5_frame'*/}
                {/*  >*/}
                {/*    <img src="/img/guideScreen/frame5.png"/>*/}
                {/*  </Text>*/}
                {/*</div>*/}
              </div>
              <div className={`guide-screen-confirm_tutorials_img`}>
                <div className='guide-screen-confirm_tutorials_img_step4'>

                  <Text
                    as="p"
                    lineHeight={24}
                    className='guide-screen-confirm_tutorials_img_step4_text'
                    color={'#fff'}
                    fontSize={17}
                    style={{ fontStyle: 'italic' }}
                  >
                    <Text
                      as="p"
                      lineHeight={24}
                      fontSize={17}
                      color={'#fff'}
                      style={{ fontStyle: 'italic' }}
                    >
                      {t('store_information_and_account_information')} .
                    </Text>
                    <Text fontSize={17} color={'#F9E429'}
                          style={{ fontStyle: 'italic' }}>{t('system_installation_and_check_for_latest_notifications_from_upos_1')}</Text> {t('system_installation_and_check_for_latest_notifications_from_upos_2')}
                    <Text
                      fontSize={17} color={'#F9E429'}
                      style={{ fontStyle: 'italic' }}> {t('system_installation_and_check_for_latest_notifications_from_upos_3')}</Text>
                    <br/> {t('system_installation_and_check_for_latest_notifications_from_upos_4')}
                  </Text>
                </div>
                {/*<div className='guide-screen-confirm_tutorials_img_step5'>*/}
                {/*  <Text*/}
                {/*    as="p"*/}
                {/*    lineHeight={24}*/}
                {/*    className='guide-screen-confirm_tutorials_img_step5_text'*/}
                {/*    fontSize={17}*/}
                {/*    color={'#fff'}*/}
                {/*    style={{ fontStyle: 'italic' }}*/}
                {/*  >*/}
                {/*    {t('store_information_and_account_information')}*/}
                {/*  </Text>*/}
                {/*</div>*/}
              </div>
              <div className='guide-screen-confirm_tutorial_step6'>
                <Text
                  className='guide-screen-confirm_tutorial_step6_frame'
                >
                  <img src="/img/guideScreen/frame6.png"/>
                </Text>
                <Text
                  className='guide-screen-confirm_tutorial_step6_text'
                  color={'#fff'}
                  fontSize={17}
                  as={'p'}
                  lineHeight={24}
                  style={{ fontStyle: 'italic' }}
                >
                  {t('contact_upos_or_view_system_usage_guidelines_1')} <Text fontSize={17} color={'#F9E429'}
                                                                              style={{ fontStyle: 'italic' }}> evoshop </Text> {t('contact_upos_or_view_system_usage_guidelines_2')}
                  <Text fontSize={17} color={'#F9E429'} style={{ fontStyle: 'italic' }}
                  > {t('contact_upos_or_view_system_usage_guidelines_3')} </Text> {t('contact_upos_or_view_system_usage_guidelines_4')}
                </Text>
              </div>
            </div>
          </div> :
          <div>
            <Box className="guide-screen-confirm">
              <div className="guide-screen-confirm_frame">

                <img src="/img/guideScreen/logo.png"/>
                <div className="guide-screen-confirm_frame-welcome">
                  <Text color={'#fff'}
                        as={'p'}
                        fontSize={24} lineHeight={38}>
                    {t('welcome_to_evoshop')}
                  </Text>
                </div>
                <div className='guide-screen-confirm_button'>
                  <Button className='guide-screen-confirm_next_start'
                          onClick={() => updateFirstLogin()}>
                    <div className='guide-screen-confirm_next_start_text'>
                      {t('get_started')}
                    </div>
                    <img src="/img/guideScreen/next.png"/>
                  </Button>
                </div>
                <a href="https://help.evoshop.vn" target={'__blank'}>
                  <Text
                    as="p"
                    lineHeight={24}
                    className='guide-screen-confirm_text_tutorial'
                  >
                    {t('view_detailed_usage_instructions')}
                  </Text></a>
              </div>
            </Box>
            <div
              className={`guide-screen-confirm_tutorial_step7 ${width > 1599 ? '' : 'guide-screen-confirm_tutorial_step7_minimize'}`}>
              <Text
                className='guide-screen-confirm_tutorial_step71_frame'
              >
                <img src="/img/guideScreen/frame71.png"/>
              </Text>
              <Text
                className='guide-screen-confirm_tutorial_step72_frame'
              >
                <img src="/img/guideScreen/frame72.png"/>
              </Text>

              <Text
                className='guide-screen-confirm_tutorial_step7_text'
                color={'#fff'}
                fontSize={17}
                as={'p'}
                lineHeight={24}
                style={{ fontStyle: 'italic' }}
              >{t('overview_of_shop_sales_activities_for_7_or_15_days_1')} <Text
                fontSize={17} color={'#F9E429'}
                style={{
                  fontStyle: 'italic',
                  textAlign: 'center'
                }}>{t('overview_of_shop_sales_activities_for_7_or_15_days_2')}</Text> {t('overview_of_shop_sales_activities_for_7_or_15_days_3')}
              </Text>
            </div>
            <div
              className={`guide-screen-confirm_tutorial_step8 ${width > 1440 ? '' : 'guide-screen-confirm_tutorial_step8_minimize'}`}>
              <Text
                as="p"
                lineHeight={24}
                className='guide-screen-confirm_tutorial_step8_frame'
              >
                <img src="/img/guideScreen/frame8.png"/>
              </Text>
              <Text
                className='guide-screen-confirm_tutorial_step8_text'
                color={'#fff'}
                fontSize={17}
                as={'p'}
                lineHeight={24}
                style={{ fontStyle: 'italic', textAlign: 'center' }}
              >{t('information_or_promotions_from_upos_1')} <Text
                fontSize={17} color={'#F9E429'}
                style={{ fontStyle: 'italic' }}> {t('information_or_promotions_from_upos_2')} </Text> {t('information_or_promotions_from_upos_3')}
              </Text>
            </div>
            <div
              className={`guide-screen-confirm_tutorial_step9 ${width > 1440 ? '' : 'guide-screen-confirm_tutorial_step9_minimize'}`}>
              <Text
                as="p"
                lineHeight={24}
                className='guide-screen-confirm_tutorial_step9_frame'
              >
                <img src="/img/guideScreen/frame9.png"/>
              </Text>
              <Text
                className='guide-screen-confirm_tutorial_step9_text'
                color={'#fff'}
                fontSize={17}
                as={'p'}
                lineHeight={24}
                style={{ fontStyle: 'italic', textAlign: 'center' }}
              >{t('reminders_to_check_abnormal_orders_and_order_related_issues_during_the_day_1')} <Text
                fontSize={17} color={'#F9E429'}
                style={{ fontStyle: 'italic' }}> {t('reminders_to_check_abnormal_orders_and_order_related_issues_during_the_day_2')} </Text> {t('reminders_to_check_abnormal_orders_and_order_related_issues_during_the_day_3')}
              </Text>
            </div>
          </div>
        }
      </StyledGuideScreen>
    </Modal>

  )
}
