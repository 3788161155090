import { RightSightPopup } from '../../layouts/rightSightPopup'
import React from 'react'
import useGlobalContext from '../../containerContext/storeContext'
import config from '../../config'

export const ChatJnt = () => {

  const [state, dispatch] = useGlobalContext()

  return (
    <RightSightPopup
      openModal={state.showChatJnt}
      confirmBeforeClose={true}
      clickClose={() => dispatch({ type: 'DISPLAY_CHAT_JNT', payload: false })}
      // animationClose={data.tags.animate}
      className={'fb-right-sight__popup'}
      footerProps={{
        style: {
          display: 'none'
        }
      }}
      bodyProps={{
        style: {
          margin: '-24px'
        }
      }}
      body={[
        {
          item: <iframe src={`${config.CHAT_CSKH}`} scrolling="auto"
                        frameBorder="0" className="iframe-jnt"></iframe>
        }
      ]}
    />
  )
}