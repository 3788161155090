export const noticeCompState = {
    notice:{
        all:{
            list:[],
            perPage:20,
            total:0,
        },
    },
    totalUnread:{
        all:0,
    },
    detailNotice:{},
    openModal:false,
    loading:false,
    activeTab: 0,
    canLoadMore:true,
    idTab:'all'
}