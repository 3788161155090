import noImg from '../topproduct/images/no-img.png'
import ReactImageFallback from 'react-image-fallback'
import React, {useContext, useEffect, useState} from 'react';
import "./index.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {DashboardContext} from "../../controlPanel";
import {Modal} from "@mui/material";
export default function () {
  const isShow = JSON.parse(window.localStorage.getItem('isShowPopupEvo'))
  const [open, setOpen] = useState(false)
  const {state} = useContext(DashboardContext)
  const {banner, popup} = state.bannerPopup
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    arrows: false
  };
  const banners = banner?.length > 0 ? banner : [
    { image: "img/controlpanel/right_layout/banner_evo.png" },
    { image: "img/controlpanel/right_layout/banner_evo.png" }
  ]
  const showFallBack = () => {
    if (banners) {
      return banners.map((item, index) => {
        return (
            <div className={'banner-items'}>
              <ReactImageFallback
                  key={index}
                  src={item.image}
                  fallbackImage={banners[index]?.image}
                  alt="img bottm img"
                  className="product-image"
                  style={{
                    cursor: item?.type_active === 'link' && !!item?.link && 'pointer'
                  }}
                  onClick={()=>{
                    if(item?.type_active === 'link' && !!item?.link) window.open(item?.link,'_blank')
                  }}
              />
            </div>
        )
      })
    }
  }
  const handleClose = _ =>{
    setOpen(false)
    window.localStorage.setItem('isShowPopupEvo', JSON.stringify({
      id: popup[0]?.id,
      status: false,
    }))
  }
  const close = <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36.9705" height="36.9705" transform="translate(0.0294189)" fill="white" fillOpacity="0.2"/>
    <path d="M25.4467 11.5533L11.5828 25.4173M11.5828 11.5533L25.4467 25.4173" stroke="white" strokeWidth="2.31065" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  useEffect(()=>{
    setOpen(isShow?.status)
  },[isShow?.status])
  return (
    <div>
      <Slider {...settings}>
          {showFallBack()}
      </Slider>
      {
        !!open && <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display:"flex",
              alignItems:'center',
              justifyContent:'center',
            }}

        >
          <div className={'popup-items'} style={{cursor: popup[0]?.type_active === 'link' && !!popup[0]?.link && 'pointer'}}>
            {
              popup?.length > 0 && <ReactImageFallback
                  src={popup[0]?.image}
                  fallbackImage={noImg}
                  alt="img bottom img"
                  className="product-image_popup"
                  onClick={()=>{
                    if(popup[0]?.type_active === 'link' && !!popup[0]?.link)
                      window.open(popup[0]?.link,'_blank')
                  }}
              />
            }
            <div className={'popup-items_close'} onClick={handleClose}>
              {close}
            </div>
          </div>
        </Modal>
      }
    </div>
  )
}