export const ERROR_STORE={
    NAME:{
        EMPTY_NAME: 'store_config_empty_store',
        MAX_NAME: 'store_config_max_store',

    },
    PHONE:{
        EMPTY_PHONE: 'store_config_empty_phone',
        MAX_PHONE:'store_config_min_max_phone',

    },
    EMAIL:{
        REGEX_EMAIL: 'please_enter_valid_email_format',
        MAX_EMAIL: 'store_config_max_email',
    },
    IMAGE: 'img_size_2MB',
}