import React from "react";

export const ICONS_TYPE_OF_RECEIPT = {
  question: (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 14.5C12.366 14.5 15.5 11.366 15.5 7.5C15.5 3.63401 12.366 0.5 8.5 0.5C4.63401 0.5 1.5 3.63401 1.5 7.5C1.5 11.366 4.63401 14.5 8.5 14.5Z" stroke="#808089" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.5 12.167C8.98325 12.167 9.375 11.7752 9.375 11.292C9.375 10.8087 8.98325 10.417 8.5 10.417C8.01675 10.417 7.625 10.8087 7.625 11.292C7.625 11.7752 8.01675 12.167 8.5 12.167Z" fill="#808089"/>
      <path d="M8.49998 8.66667V8.08333C8.90378 8.08333 9.29852 7.96359 9.63427 7.73925C9.97002 7.51491 10.2317 7.19605 10.3862 6.82298C10.5408 6.44991 10.5812 6.0394 10.5024 5.64336C10.4236 5.24731 10.2292 4.88352 9.94366 4.59799C9.65812 4.31246 9.29433 4.11801 8.89829 4.03923C8.50224 3.96045 8.09173 4.00088 7.71867 4.15541C7.3456 4.30994 7.02674 4.57163 6.8024 4.90738C6.57805 5.24313 6.45831 5.63786 6.45831 6.04167" stroke="#808089" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ),
  plus: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4V16M4 10H16"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  repeat: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.58333 17.4165C13.4954 17.4165 16.6667 14.2452 16.6667 10.3332C16.6667 7.97856 15.5178 5.89228 13.75 4.60434M10.4167 18.9998L8.75 17.3332L10.4167 15.6665M9.58333 3.24984C5.67132 3.24984 2.5 6.42115 2.5 10.3332C2.5 12.6878 3.64889 14.7741 5.41667 16.062M8.75 4.99984L10.4167 3.33317L8.75 1.6665"
        stroke="#1E9A98"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  edit: (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.25 3.00005H4.1C2.83988 3.00005 2.20982 3.00005 1.72852 3.24528C1.30516 3.461 0.96095 3.80521 0.745235 4.22857C0.5 4.70987 0.5 5.33993 0.5 6.60005V12.9C0.5 14.1602 0.5 14.7902 0.745235 15.2715C0.96095 15.6949 1.30516 16.0391 1.72852 16.2548C2.20982 16.5 2.83988 16.5 4.1 16.5H10.4C11.6601 16.5 12.2902 16.5 12.7715 16.2548C13.1948 16.0391 13.539 15.6949 13.7548 15.2715C14 14.7902 14 14.1602 14 12.9V9.75005M4.99998 12H6.25589C6.62277 12 6.80622 12 6.97885 11.9586C7.1319 11.9219 7.27822 11.8613 7.41243 11.779C7.5638 11.6862 7.69352 11.5565 7.95294 11.2971L15.125 4.12505C15.7463 3.50373 15.7463 2.49637 15.125 1.87505C14.5037 1.25373 13.4963 1.25373 12.875 1.87505L5.70293 9.0471C5.4435 9.30653 5.31378 9.43625 5.22102 9.58762C5.13878 9.72183 5.07817 9.86815 5.04143 10.0212C4.99998 10.1938 4.99998 10.3773 4.99998 10.7442V12Z" stroke="#6E6D7A" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  remove: (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 4.5V3.9C12 3.05992 12 2.63988 11.8365 2.31901C11.6927 2.03677 11.4632 1.8073 11.181 1.66349C10.8601 1.5 10.4401 1.5 9.6 1.5H8.4C7.55992 1.5 7.13988 1.5 6.81901 1.66349C6.53677 1.8073 6.3073 2.03677 6.16349 2.31901C6 2.63988 6 3.05992 6 3.9V4.5M7.5 8.625V12.375M10.5 8.625V12.375M2.25 4.5H15.75M14.25 4.5V12.9C14.25 14.1601 14.25 14.7902 14.0048 15.2715C13.789 15.6948 13.4448 16.039 13.0215 16.2548C12.5402 16.5 11.9101 16.5 10.65 16.5H7.35C6.08988 16.5 5.45982 16.5 4.97852 16.2548C4.55516 16.039 4.21095 15.6948 3.99524 15.2715C3.75 14.7902 3.75 14.1601 3.75 12.9V4.5" stroke="#FF424E" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ),
  manipulation: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2008 4.8C10.2008 5.79411 11.0067 6.6 12.0008 6.6C12.9949 6.6 13.8008 5.79411 13.8008 4.8C13.8008 3.80589 12.9949 3 12.0008 3C11.0067 3 10.2008 3.80589 10.2008 4.8Z"
        fill="#7C88A6"
      />
      <path
        d="M10.2008 12.0002C10.2008 12.9943 11.0067 13.8002 12.0008 13.8002C12.9949 13.8002 13.8008 12.9943 13.8008 12.0002C13.8008 11.0061 12.9949 10.2002 12.0008 10.2002C11.0067 10.2002 10.2008 11.0061 10.2008 12.0002Z"
        fill="#7C88A6"
      />
      <path
        d="M10.2008 19.1999C10.2008 20.194 11.0067 20.9999 12.0008 20.9999C12.9949 20.9999 13.8008 20.194 13.8008 19.1999C13.8008 18.2058 12.9949 17.3999 12.0008 17.3999C11.0067 17.3999 10.2008 18.2058 10.2008 19.1999Z"
        fill="#7C88A6"
      />
    </svg>
  ),
  manipulation_hover: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2008 4.8C10.2008 5.79411 11.0067 6.6 12.0008 6.6C12.9949 6.6 13.8008 5.79411 13.8008 4.8C13.8008 3.80589 12.9949 3 12.0008 3C11.0067 3 10.2008 3.80589 10.2008 4.8Z"
        fill="#1E9A98"
      />
      <path
        d="M10.2008 11.9992C10.2008 12.9933 11.0067 13.7992 12.0008 13.7992C12.9949 13.7992 13.8008 12.9933 13.8008 11.9992C13.8008 11.0051 12.9949 10.1992 12.0008 10.1992C11.0067 10.1992 10.2008 11.0051 10.2008 11.9992Z"
        fill="#1E9A98"
      />
      <path
        d="M10.2008 19.2004C10.2008 20.1945 11.0067 21.0004 12.0008 21.0004C12.9949 21.0004 13.8008 20.1945 13.8008 19.2004C13.8008 18.2063 12.9949 17.4004 12.0008 17.4004C11.0067 17.4004 10.2008 18.2063 10.2008 19.2004Z"
        fill="#1E9A98"
      />
    </svg>
  ),
  caretRight: (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.76644 8.51719L10.5164 4.76719C10.5673 4.71297 10.6018 4.64545 10.6159 4.57246C10.63 4.49946 10.6231 4.42396 10.5961 4.35469C10.5674 4.28644 10.5192 4.22821 10.4575 4.18733C10.3957 4.14646 10.3233 4.12477 10.2493 4.125L2.74926 4.125C2.67522 4.12477 2.60277 4.14646 2.54105 4.18733C2.47932 4.22821 2.43107 4.28644 2.40238 4.35469C2.38401 4.40094 2.37447 4.45023 2.37426 4.5C2.37468 4.59958 2.41326 4.69521 2.48207 4.76719L6.23207 8.51719C6.30316 8.58761 6.39919 8.62711 6.49925 8.62711C6.59932 8.62711 6.69534 8.58761 6.76644 8.51719Z"
        fill="white"
      />
    </svg>
  ),
  searchMd:(
   <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.505 18.495C17.7784 18.7683 18.2216 18.7683 18.495 18.495C18.7683 18.2216 18.7683 17.7784 18.495 17.505L17.505 18.495ZM14.6283 13.6384C14.3549 13.365 13.9117 13.365 13.6384 13.6384C13.365 13.9117 13.365 14.3549 13.6384 14.6283L14.6283 13.6384ZM18.495 17.505L14.6283 13.6384L13.6384 14.6283L17.505 18.495L18.495 17.505ZM15.5222 9.11111C15.5222 12.6519 12.6519 15.5222 9.11111 15.5222V16.9222C13.4251 16.9222 16.9222 13.4251 16.9222 9.11111H15.5222ZM9.11111 15.5222C5.57035 15.5222 2.7 12.6519 2.7 9.11111H1.3C1.3 13.4251 4.79715 16.9222 9.11111 16.9222V15.5222ZM2.7 9.11111C2.7 5.57035 5.57035 2.7 9.11111 2.7V1.3C4.79715 1.3 1.3 4.79715 1.3 9.11111H2.7ZM9.11111 2.7C12.6519 2.7 15.5222 5.57035 15.5222 9.11111H16.9222C16.9222 4.79715 13.4251 1.3 9.11111 1.3V2.7Z"
          fill="#56697C"
        />
      </svg>)
}