export const ORDER_SINGLE_CONSTANTS = {
  create: {
    shippingPoint: {
      options: [
        {id: 1, name: 'set_as_default_address', value: 'is_default'},
        {id: 2, name: 'hide_name_on_shipping_label', value: 'is_hidden_name'},
        {
          id: 3,
          name: 'hide_address_on_shipping_label',
          value: 'is_hidden_address',
        },
        {
          id: 4,
          name: 'hide_phone_on_shipping_label',
          value: 'is_hidden_phone',
        },
        {
          id: 5,
          name: 'hide_address_province_district_on_shipping_label',
          value: 'is_hidden_province',
        },
      ],
    },
  },
  header: {
    breadcrumb: [
      {
        id: 1,
        name: 'order',
        url: '#',
      },
      {id: 2, name: 'manage_order', url: '#'},
      {id: 3, name: 'create_new_order', url: '#'},
    ],
  },
  form: {
    productInfo: {
      inventoryType: [
        {
          id: 1,
          name: 'enter_product_name',
          value: 'manual',
        },
        {
          id: 2,
          name: 'use_existing_product_name',
          value: 'auto',
        },
      ],
      withInventoryPriceType: [
        {name: 'general_retail_price', value: 1},
        {name: 'general_wholesale_price', value: 2},
      ],
    },
    shippingInfo: {
      1: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          {value: 3, label: 'sender_pay_end_month', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      2: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
        ],
        transport: [
          {value: '', label: 'default', checked: true},
          {value: 'road', label: 'by_road', checked: false},
          {value: 'fly', label: 'by_air', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        pick_date: null,
        pick_shift: [
          {value: 1, label: 'morning', checked: false},
          {value: 2, label: 'afternoon', checked: false},
          {value: 3, label: 'evening', checked: false},
        ],
        cargoInsurrance: {
          value: '',
          active: true,
        },
      },
      3: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
          {value: 3, label: 'sample_goods', checked: false},
        ],
        cargoInsurrance: {
          active: false,
          value: '',
        },
        lengh: 1,
        width: 2,
        height: 3,
      },
      4: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      5: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          {value: 3, label: 'sender_pay_end_month', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      6: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      7: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          {value: 3, label: 'sender_pay_end_month', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      8: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          {value: 3, label: 'sender_pay_end_month', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      9: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          {value: 3, label: 'sender_pay_end_month', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      10: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          {value: 3, label: 'sender_pay_end_month', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
    },
    paymentMethod: {
      listType: [
        {id: 1, name: 'prepaid_payment', value: 'before'},
        {id: 2, name: 'cod_payment_after_successful_delivery', value: 'cod'},
        {id: 3, name: 'payment_after_delivery', value: 'after'},
      ],
    },
  },
  printModal: {
    pageSize: {
      list: [
        {
          name: 'print_J&T_Express_order',
          value: 'jt',
          type: 'partner'
        },
        {
          name: 'print_J&T_Express_a4',
          value: 'a4',
          type: 'partner'
        },
        {
          name: 'print_J&T_Express_a5',
          value: 'a5',
          type: 'partner'
        },
        {
          name: 'print_J&T_Express_a6',
          value: 'a6',
          type: 'partner'
        },
        {
          name: 'print_EVO_A4_template',
          value: 'a4',
          type: 'upos'
        },
        {
          name: 'print_EVO_A5_template',
          value: 'a5',
          type: 'upos'
        },
        {
          name: 'print_EVO_K80_template',
          value: 'k80',
          type: 'upos'
        },
      ],
    },
  },

  errorFeeShipping: {
    data: {
      errors: {
        code: 101,
        message: '101',
        details: {
          code: 2022,
          message: 'incorrect_shipping_fee_information'
        }
      }
    }
  }
}
