import {useState} from "react";
import {sendRequest, sendRequestAuth} from "../../../api/api";
import config from "../../../config";
import useGlobalContext from "../../../containerContext/storeContext";

export const useControlPanel = (state,dispatch) =>{
    const [openModal, setOpenModal] = useState(false)
    const [detailNotice,setDetailNotice] = useState({})
    const [stateGlobal, dispatchGlobal] = useGlobalContext()
    const closeModal = ()=>{
        setOpenModal(false)
        fetchAfterCloseModal()
    }

    const fetchAfterCloseModal = async ()=>{
        const res = await sendRequest('get',`${config.API}/notices/list?per_page=20&start=0&type=0`)
        if (res.data.success) {
            const filterData = res.data?.data?.filter(filter => +filter?.is_popup === 0 || +filter?.is_read === 1  )
            dispatchGlobal({
                type: 'NOTICE_TOTAL_UNREAD', payload: {
                    all: filterData?.filter(filter => +filter?.is_read === 0).length,
                    notice:{
                        allList:{
                            list:filterData,
                            total: res.data.meta.total
                        },
                    }
                }
            })
        }
    }
    const checkDateTime = (start, end)=>{
        const startExprite = new Date(start)
        const endExprite = new Date(end)
        const date = new Date()
        if(startExprite?.getTime() <= date?.getTime() && endExprite?.getTime() >= date?.getTime()) return true
        return false
    }

    const checkIsPopup =  async (data)=>{
        const response = await sendRequestAuth('get', `${config.API}/notices/detail/${data?.id}`)
        if(response?.data?.success){
            setOpenModal(true)
            setDetailNotice(response?.data?.data)
        }
    }
    const fetchNotice = async ()=>{
        const res = await sendRequestAuth('get', `${config.API}/notices/list?per_page=20&start=0&type=0`)
        if (res.data.success) {
            const filterData = res.data?.data?.filter(filter => +filter?.is_popup === 0 || +filter?.is_read === 1  )
            const findData = res.data?.data?.find(find =>{
                const checkDate = checkDateTime(find?.started_at, find?.ended_at)
                if(+find?.is_popup === 1 && +find?.is_read === 0 && !!checkDate) return find
            })
            if(!!findData)  checkIsPopup(findData)
            dispatchGlobal({
                type: 'NOTICE_TOTAL_UNREAD', payload: {
                    all: filterData?.filter(filter => +filter?.is_read === 0).length,
                    notice:{
                        allList:{
                            list:filterData,
                            total: res.data.meta.total
                        },
                    }
                }
            })
        }
    }

    return{
        data:{
          openModal,
          detailNotice,
        },
        functions:{
            closeModal: closeModal,
            origin: fetchNotice,
            handleOpen: setOpenModal,
        }
    }
}